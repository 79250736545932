import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { ComplaintService } from '../../shared/services/complaint.service';
import { ComplaintTypeService } from '../../shared/services/complaint-type.service';

import { Complaint } from '../../shared/models/complaint.model';
import { ComplaintType } from '../../shared/models/complaint-type.model';

import { CustomValidators } from 'ng2-validation';
import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { ContactService } from '../../shared/services/contact.service';
import { UserService } from "../../shared/services/user.service";

import { CrmUser } from "../../shared/models/user.model";
import { Contact } from '../../shared/models/contact.model';
import { ComplaintStatus } from '../../shared/models/complaint-status.model';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';


@Component({
  selector: 'app-complaint-manage',
  templateUrl: './complaint-manage.component.html',
  styleUrls: ['./complaint-manage.component.scss']
})
export class ComplaintManageComponent implements OnInit {

  complaintForm: FormGroup;                         // - Stores the complaint form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update a complaint if true and add a new complaint if false

  complaints: Complaint[] = [];                   // - Stores all complaints retrieved from db to validate unique values
  complaint: Complaint = new Complaint();         // - Stores Complaint to populate form with a new / existing complaint's values
 
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this complaint
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this complaint
  complaintStatusOptions:SelectItem[] = [];       // - Stores Complaint Status 
  complaintTypesOptions:SelectItem[] = [];        // - Stores Complaint Types 

  //** DatePicker */
  maxDate: Date = new Date(); // - Can't select a date before the current day
  dateRange: string = this.maxDate.getFullYear() + ':' + (this.maxDate.getFullYear() + 10); // - 10 Year Range

  //** Spinner (Calendar event duration) */
  defaultCalendarDuration: number = 30; // - Default Duration
  minCalendarDuration: number = 5;      // - Duration in Minutes
  maxCalendarDuration: number = 600;

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;
  constructor(
    public authService: AuthService,
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private complaintService: ComplaintService,
    private complaintTypeService: ComplaintTypeService,
    private confirmationService: ConfirmationService,
    private contactService: ContactService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.setupForm();    
  }



  initForm() {
    this.complaintForm = this.formBuilder.group({
      code: this.formBuilder.control(this.complaint.code),
      reportedDate: this.formBuilder.control(
        this.complaint.reportedDate ? new Date(this.complaint.reportedDate) : this.formatMinutesInDateToNearestFiveMinutes(new Date()),
        [Validators.required]),
      description: this.formBuilder.control(this.complaint.description, [Validators.required]),
      status: this.formBuilder.control(this.complaint.status === AuditManager.StatusActive || !this.complaint.status ? true : false),
      assignedContactId :this.formBuilder.control(this.complaint.assignedContactId),
      assignedUserAccountId :this.formBuilder.control(this.complaint.assignedUserAccountId),
      complaintTypeId :this.formBuilder.control(this.complaint.complaintTypeId),
      complaintStatusId :this.formBuilder.control(this.complaint.complaintStatusId),
      });

    this.onGetUsers();  // - Retrieve all users to populate the Assigned User dropdown
    this.onGetComplaintTypes();
    this.onGetComplaintStatus();

    if (this.isEdit && this.complaint.assignedContact) {
      let c = this.complaint.assignedContact;
      let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
      c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
      c.companyName ? labelText += " / Company: " + c.companyName : "";
      c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
      c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
      c.email ? labelText += " / Email: " + c.email : "";

      let defaultSelectItem: SelectItem = {
        label: labelText,
        value: this.complaint.assignedContactId
      };

      let defaultValue = this.complaint.assignedContactId ? defaultSelectItem : null;

      this.complaintForm.patchValue({
        assignedContactId: defaultValue
      });
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {

          this.contactService.getContact(+contactId).subscribe(
            (contact: Contact) => {
              let defaultValue: SelectItem = {
                label: contact.fullName,
                value: +contactId
              };

              this.complaintForm.patchValue({
                defaultContactId: defaultValue
              });
            }
          );
        }
      }
    );

   
    this.isFormInit = true;
  }

  


  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const complaintId = params['id'];
        this.isEdit = complaintId != null; // - Returns true if an id was present within the url

        if (this.isEdit) {
          this.complaintService.getComplaint(+complaintId).subscribe(
            (complaint: Complaint) => {
              this.complaint = complaint;

              this.initForm();
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving complaint", error);
            }
          );

          // Redirect to 404 page if the selected complaint could not be found
          if (!this.complaint) {
            this.router.navigate(['/error/404']);
            return;
          }
        }
        else
          this.initForm();
      }
    );
  }

  onSubmit() {
    let newComplaint = this.formBuilder.sanitizeFormValues(this.complaintForm).value;

    // Status is a checkbox value so only true or false can be returned
    // True - Active Complaint, False - Hidden Complaint
    if (newComplaint.status)
      newComplaint.status = AuditManager.StatusActive;
    else
      newComplaint.status = AuditManager.StatusHidden;

    if (newComplaint.assignedContactId && newComplaint.assignedContactId.value)
    newComplaint.assignedContactId = newComplaint.assignedContactId.value;
    else
    newComplaint.assignedContactId = null;

    if (this.isEdit) {
      newComplaint.id = this.complaint.id; // - Set id of edited complaint to its original id, same with externalId and createdById
      newComplaint.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateComplaint(newComplaint);
    }
    else {
      newComplaint.code = "tempCode" // this will change to COM000+ID of the complaint inserted
      newComplaint.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddComplaint(newComplaint);
    }
  }

  onAddComplaint(newComplaint: Complaint) {
    
    this.complaintService.addComplaint(newComplaint).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The complaint " + newComplaint.code + " has been created.");

        let complaint: Complaint = (<any>response) as Complaint; // - Can't use NewComplaint as we need the complaint's ID retrieved from db after creation

          this.router.navigate(['/snaglist/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding complaint", error);
      }
    );
  }

  onUpdateComplaint(newComplaint: Complaint) {
    this.complaintService.updateComplaint(newComplaint).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The complaint " + newComplaint.code  + " has been updated.");

          this.router.navigate(['/snaglist/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating complaint", error);
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.assignedContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.complaint.assignedUserAccountId ? this.complaint.assignedUserAccountId : this.authService.applicationProfileUser().id;

        this.complaintForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  onGetComplaintTypes() {
    this.complaintTypeService.getComplaintTypes().subscribe(
      (complaintTypes: ComplaintType[]) => {
        // Store retrieved complaint types in the complaintTypesOptions select list
        complaintTypes.map(cT => this.complaintTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = null;

        if (this.isEdit)
          defaultValue = this.complaint.complaintType ? this.complaint.complaintTypeId : null;

        this.complaintForm.patchValue({
          complaintTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost complaint types", error);
      }
    );
  }

  onGetComplaintStatus() {
    this.complaintService.getComplaintStatuses().subscribe(
      (complaintStatus: ComplaintStatus[]) => {
        // Store retrieved complaint status in the complaintStatusOptions select list
        complaintStatus.map(cT => this.complaintStatusOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = null;

        if (this.isEdit)
          defaultValue = this.complaint.complaintStatus ? this.complaint.complaintStatusId : null;

        this.complaintForm.patchValue({
          complaintStatusId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost complaint status", error);
      }
    );
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }


  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
  }

  formatMinutesInDateToNearestFiveMinutes(date: Date) {
    // Obtained from: https://stackoverflow.com/a/10789415
    let coeff = 1000 * 60 * 5;
    let rounded = new Date(Math.round(date.getTime() / coeff) * coeff)

    return rounded;
  }


}
