import { Component, OnInit } from '@angular/core';
import { Response, RequestOptions } from '@angular/http';

import { FormGroup, Validators } from '@angular/forms';
import { CustomFormBuilder } from './../../shared/classes/CustomFormBuilder';
import { SelectItem } from 'primeng/components/common/api';

import { ToastService } from '../../shared/services/toast.service';
import { ContactSourceService } from '../../shared/services/contact-source.service';
import { ContactTypeService } from '../../shared/services/contact-type.service';
import { ContactService } from '../../shared/services/contact.service';

import { ContactType } from '../../shared/models/contact-type.model';
import { ContactSource } from '../../shared/models/contact-source.model';
import { ApiEndPoints, PrepopulatedContactTypes } from '../../../../app.constant';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { AuthService } from '../../auth/auth.service';
import { AppSettings } from 'app.settings';
import { LogItemView, LogModel } from 'app/views/shared/models/log-model.model';

@Component({
  selector: 'app-contact-import',
  templateUrl: './contact-import.component.html',
  styleUrls: ['./contact-import.component.scss']
})
export class ContactImportComponent implements OnInit {

  contactImportForm: FormGroup;                   // - Stores the contact import form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised

  contactTypesOptions: SelectItem[] = [];         // - Stores Contact Type Dropdown Options
  contactSourcesOptions: SelectItem[] = [];       // - Stores Contact Source Dropdown Options


  crmApiEndPoint: string = AppSettings.API_ENDPOINT;
  accessToken = localStorage.getItem('accessToken');

  logs: LogModel = new LogModel()
  logItems: LogItemView[] = []

  //** Dropzone */
  DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: AppSettings.API_ENDPOINT + 'api/ImportExcel/ImportDataFromWorkbook/',
    headers: {
      'Authorization': 'Bearer ' + this.authService.getUser().access_token
    },
    paramName: "uploadFile",
    parallelUploads: 1,
    errorReset: 3,
    acceptedFiles: ".csv,.xlsx,.xls",
    timeout: 600000, // - 10 Mins max
    maxFilesize: 50 // - 50MB
  };

  constructor(
    private formBuilder: CustomFormBuilder,
    private authService: AuthService,
    private contactService: ContactService,
    private contactTypeService: ContactTypeService,
    private contactSourceService: ContactSourceService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetContactTypes();     // - Retrieve all contact types to populate the Contact Type dropdown
    this.onGetContactSources();   // - Retrieve all contact sources to populate the Contact Source dropdown

    this.initForm();
  }

  initForm() {
    this.contactImportForm = this.formBuilder.group({
      contactTypeId: this.formBuilder.control(null, [Validators.required]),
      contactSourceId: this.formBuilder.control(null, [Validators.required]),
      csvData: this.formBuilder.control(null)
    });

    this.isFormInit = true;
  }

  onUploadError($event) {
    this.toastService.createErrorMessage("Error uploading files", $event[2] ? $event[2].response : $event[1]);
  }

  onUploadSuccess($event) {
    console.log("UPLOAD SUCCESS EVENT: ", $event);
    this.populatedLogItems($event[1] || new LogModel())
    // this.toastService.createSuccessMessage("Success", $event[1]);
  }

  onSending($event) {
    // let file = $event[0];
    // let xmlHttpRequest = $event[1];
    // let formData: FormData = $event[2];

    // let contactTypeId = this.contactImportForm.controls['contactTypeId'].value;
    // let contactTypeName = this.contactTypesOptions.find(o => o.value == contactTypeId).label;
    // let contactSourceId = this.contactImportForm.controls['contactSourceId'].value;

    // if (contactTypeName === PrepopulatedContactTypes.Customer || contactTypeName == PrepopulatedContactTypes.Lead) {
    //   formData.append("contactTypeId", contactTypeId);
    //   formData.append("contactTypeName", contactTypeName);
    //   formData.append("contactSourceId", contactSourceId);
    //   formData.append("createdByUserAccountId", this.authService.applicationProfileUser().id);
    // }
  }

  onGetContactTypes() {
    this.contactTypeService.getContactTypes().subscribe(
      (contactTypes: ContactType[]) => {
        // Store retrieved contact types in the contactTypesOptions select list
        contactTypes.map(cT => this.contactTypesOptions.push({ label: cT.name, value: cT.id }));

        // There must be at least one Contact Type created to create or edit a contact
        if (this.contactTypesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = this.contactTypesOptions[0].value;
        this.contactImportForm.patchValue({
          contactTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  onGetContactSources() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        // Store retrieved contact sources in the contactSourcesOptions select list
        contactSources.map(cS => this.contactSourcesOptions.push({ label: cS.name, value: cS.id }));

        let defaultValue = this.contactSourcesOptions[0].value;
        this.contactImportForm.patchValue({
          contactSourceId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  populatedLogItems(model: LogModel) {
    try {
      const result: LogItemView[] = []
      model.sections.forEach(section => 
        section.items.forEach(item => {
          result.push({
            index: item.index,
            message: item.message,
            sectionName: section.name,
            success: item.isInserted
          })
        })
      )
      this.logItems = result
    } catch (e) {
      console.log(e);
    }
  }

}
