export class LogModel {
  constructor(
    public id: number = 0,
    public sections: LogSectionModel[] = []
  ) { }
}

export class LogSectionModel {
  constructor(
    public name: string = "",
    public items: LogItemModel[] = []
  ) { }
}

export class LogItemModel {
  constructor(
    public index: number = 0,
    public isInserted: boolean = false,
    public message: string = ""
  ) { }
}

export class LogItemView {
  constructor(
    public success: boolean = false,
    public sectionName: string = "",
    public message: string = "",
    public index: number = 0
  ) { }
}