import { Component, OnInit, ViewChild, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities, PrepopulatedContactTypes, PrepopulatedContactSources, PrepopulatedOpportunityTypes, PrepopulatedTaskTypes, GoogleMaps } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { OpportunityService } from "../../shared/services/opportunity.service";
import { OpportunityTypeService } from "../../shared/services/opportunity-type.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from "../../shared/services/user.service";
import { ContactService } from "../../shared/services/contact.service";
import { OpportunityLostTypeService } from '../../shared/services/opportunity-lost-type.service';
import { ProductService } from '../../shared/services/product.service';
import { QuoteService } from '../../shared/services/quote.service';
import { Opportunity, OpportunityExtended, SalesOpportunityFormControl, LandPurchaseOpportunityFormControl, LandPurchaseOpportunity } from "../../shared/models/opportunity.model";
import { OpportunityType } from "../../shared/models/opportunity-type.model";

import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { CrmUser } from "../../shared/models/user.model";
import { Contact } from "../../shared/models/contact.model";
import { OpportunityLostType } from '../../shared/models/opportunity-lost-type.model';
import { Product } from '../../shared/models/product.model';
import { PropertyService } from 'app/views/shared/services/property.service';
import { PropertyCategory } from 'app/views/shared/models/property-category.model';
import { PropertyLocality } from 'app/views/shared/models/property-locality.model';
import { PropertyRegion } from 'app/views/shared/models/property-region.model';
import { PropertyDealType } from 'app/views/shared/models/property-dealtype';
import { PropertyAttribute } from 'app/views/shared/models/property-attribute';
import { PropertyDetails } from 'app/views/shared/models/property-details.model';
import { pipeFromArray } from 'rxjs/internal/util/pipe';
import { OpportunitySelection } from 'app/views/shared/models/opportunity-selection.model';
import { PropertyOppSelection } from 'app/views/shared/models/property-opportunity-selection.model';
import { Property } from 'app/views/shared/models/property.model';
import { OpportunityPropertiesNotInterested } from 'app/views/shared/models/opportunity-properties-not-interested.model';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { Quote } from 'app/views/shared/models/quote.model';
import { Task } from 'app/views/shared/models/task.model';
import { TaskService } from 'app/views/shared/services/task.service';
import { Organization } from 'app/views/shared/models/organization.model';
import { BaseComponent } from 'app/views/base/base.component';
import { OpportunityInitQueryGQL } from 'app/graphql/opportunity/opportunity-init.query.graphql';
import { OpportunityCategoryType } from 'app/views/shared/models/opportunity-category-type/opportunity-category-type.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { AcquisitionOpportunityValidation } from './opportunity-manage-acquisition-item.validation';
import { AllOpportunityCategoryTypesQueryGQL } from 'app/graphql/opportunity-category-type/opportunity-category-types.graphql';
import { forkJoin, combineLatest, of } from 'rxjs';
import { FormUtilityService } from 'app/shared/form-utility.service';
import { LandPurchaseOpportunityByIdOppIdQueryGQL } from 'app/graphql/land-purchase-opportunity/land-purchase-opportunity-by-opp-id.graphql';
import { UpdateLandPurchaseOpportunityMutation } from 'app/graphql/land-purchase-opportunity/land-purchase-opportunity-update.mutation.graphql';
import { CreateLandPurchaseOpportunityMutation } from 'app/graphql/land-purchase-opportunity/land-purchase-opporunity-create.mutation.graphql';
import { AllAccountPRofilesQueryGQL } from 'app/graphql/account-profile/account-profiles.graphql';
import { Profile } from 'app/shared/profile/_models/profile.model';
import { DealTypeOpportunitiesQueryGQL } from 'app/graphql/land-purchase-opportunity/deal-type-opportunities.graphq';
import { IDealTypeOpportunity } from 'app/views/shared/models/deal-type-opportunity.model';

declare var google: any;

@Component({
  selector: 'app-acquisition-opportunity-manage',
  templateUrl: './opportunity-manage-acquisition.component.html',
  styleUrls: ['./opportunity-manage-acquisition.component.scss']
})
export class AcquisitionOpportunityManageComponent extends BaseComponent implements OnInit, OnDestroy {

  opportunityForm: FormGroup = null;                     // - Stores the opportunity form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update an opportunity if true and add a new opportunity if false
  isQuoteClosed: boolean = false;                 // - If Quote was selected and now cannot add more quotes

  opportunity: OpportunityExtended = new OpportunityExtended();   // - Stores Opportunity to populate form with a new / existing opportunity's values

  productsOptions: SelectItem[] = [];             // - Stores Products Dropdown Options
  //propertyCategoryOptions: SelectItem[] = [];             // - Stores Property Category Dropdown Options
  opportunityTypesOptions: SelectItem[] = [];     // - Stores Opportunity Type Dropdown Options
  opportunityLostTypesOptions: SelectItem[] = []; // - Stores Opportunity Lost Type Dropdown Options
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this opportunity
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity
  opportunityCategoryTypeOptions: SelectItem[] = [];
  accountProfileOptions: SelectItem[] = [];
  tasks: Task[] = [];                             // - List of tasks displayed in table
  dealTypeOpportunityOptions: SelectItem[] = [];

  isProjectCreated: boolean = false;

  validation: AcquisitionOpportunityValidation = null;

  selectedCategoryType: number = null;

  MainCatOptions: SelectItem[] = [];
  SubCatOptions: SelectItem[] = [];
  PropertyRegionsOptions: SelectItem[] = [];
  PropertyLocalitiesOptions: SelectItem[] = [];
  PropertyDealTypesOptions: SelectItem[] = [];
  PropertyRoomsOptions: SelectItem[] = [];
  PropertyFeaturesOptions: SelectItem[] = [];
  Properties: Property[] = [];
  ParamValues: string = null;
  Viewings: number[] = [];
  PropNotInterested: number[] = [];
  opportunityPropertyNotInterested: OpportunityPropertiesNotInterested = new OpportunityPropertiesNotInterested();

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  CategoryTypeEnums: { "Sales Opportunity": number; "Land Purchases Opportunity": number; };
  selectedCategoryTypeOptionId: any;
  landPurchaseOpp: LandPurchaseOpportunity;

  //** Google Maps */
  options: any;
  overlays: any[];
  marker: any;
  isGoogleMapsSearchInit = false;
  @ViewChild("gMapsSearch") public gMapsSearchElementRef: ElementRef;
  @ViewChild("gmap") public gmap;
  minDueDate: Date;
  allAccountProfiles: Profile[];
  categoryTypes: OpportunityCategoryType[] = [];
  dealTypeLabel: string;

  constructor(
    private formBuilder: CustomFormBuilder,
    private _formUtil: FormUtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private propertyService: PropertyService,
    private productService: ProductService,
    private taskService: TaskService,
    private opportunityService: OpportunityService,
    private quoteService: QuoteService,
    private opportunityTypeService: OpportunityTypeService,
    private opportunityLostTypeService: OpportunityLostTypeService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private toastService: ToastService,
    private _allOppCategoryTypes: AllOpportunityCategoryTypesQueryGQL,

    private _landPurchaseOpportunityByIdQuery: LandPurchaseOpportunityByIdOppIdQueryGQL,
    private _updateLandPurchaseOpportunityMutation: UpdateLandPurchaseOpportunityMutation,
    private _createLandPurchaseOpportunityMutation: CreateLandPurchaseOpportunityMutation,

    private _getAllDealTypeOpportunties: DealTypeOpportunitiesQueryGQL,

    private _allAccountProfiles: AllAccountPRofilesQueryGQL,

    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    // this.populateCategoryTypeMappings();
    // this.ngOnInitHandleDropdowns();
    // this.setupForm();



    //TODO: if more items are to be retrieved upon loading the land purchase opp, create a separate gql query to retrieve all
    // the items from one file .. like an init query.
    // this.getAllDealTypes();
    // this.getAllCategoryTypes();


    this.populateCategoryTypeMappings();
    let oppTypes$ = this.ngOnInitHandleDropdowns()    
    .pipe(
      tap(_=> {
        this.populateOppTypeOptions(_);        
      }),
      tap(_=> {
        this.getAllDealTypes();
    
      }),
      tap( _=> {
        this.getAllCategoryTypes();
      }),
      switchMap(() => { return this.setupForm() })
    ).subscribe(_ => {
        switch(this.selectedCategoryType) {
          case 1:
            this.opportunity = _.opp as OpportunityExtended;
            this.onGetPropertyDetails(_.oppId);
          break;

          case 2:
            this.landPurchaseOpp = _.opp as LandPurchaseOpportunity;
          break;
        }


      this.initForm();
    });
  }

  getImageUrl(prop){
    //url(' + prop.propertyUrl + prop.propertyImageUrl + ')
    let imagUrl = prop.propertyUrl + prop.propertyImageUrl;

    let link = 'url(' + imagUrl + ')';

    return link;
  }

  isPropertyViewedOrNotInterested(propId) {
    propId = parseInt(propId);

    if (this.PropNotInterested.includes(propId)) {
      return 'NotInterested';
    }
    else if (this.Viewings.includes(propId)) {
      return 'Viewed';
    }
  }

  NotInterested(prop) {
    this.confirmationService.confirm({
      header: prop.propertyPreCode + ' (Not Interested)',
      message: 'Would you like to mark this property as Not Interested?',
      accept: () => {
        this.opportunityPropertyNotInterested.OpportunityId = this.opportunity.id;
        this.opportunityPropertyNotInterested.PropertyId = prop.propertyId;
        this.opportunityService.addPropertyNotInterested(this.opportunityPropertyNotInterested).subscribe(
          (response: Response) => {
            this.toastService.createSuccessMessage("Success", "The property has been listed as not interested.");
            //this.router.navigate(['/opportunities/' + this.opportunity.id + '/edit']);

            this.PropNotInterested.push(prop.propertyId);
            this.isPropertyViewedOrNotInterested(prop.propertyId);
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error updating properties not interested", error);
          }
        );
      }
    });
  }

  UndoNotInterested(prop) {
    this.confirmationService.confirm({
      header: prop.propertyPreCode + ' (Undo Not Interested)',
      message: 'Would you like to undo Not Interested for this property ?',
      accept: () => {
        this.opportunityPropertyNotInterested.OpportunityId = this.opportunity.id;
        this.opportunityPropertyNotInterested.PropertyId = prop.propertyId;
        this.opportunityService.undoPropertyNotInterested(this.opportunityPropertyNotInterested).subscribe(
          (response: Response) => {
            this.toastService.createSuccessMessage("Success", "Property not interested has been removed.");
            //this.router.navigate(['/opportunities/' + this.opportunity.id + '/edit']);

            let index = this.PropNotInterested.indexOf(prop.propertyId);
            this.PropNotInterested.splice(index);
            this.isPropertyViewedOrNotInterested(prop.propertyId);
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error undo properties not interested", error);
          }
        );
      }
    });
  }

  private populateCategoryTypeMappings() {
    this.CategoryTypeEnums = {
      "Sales Opportunity": 1,
      "Land Purchases Opportunity": 2
    };
  }

  ngOnDestroy() : void {
    super.ngOnDestroy();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  getAllCategoryTypes(): void {
    this.subscriptions$.push(
      this.allOppCategoryTypes$().subscribe(res => {
        this.categoryTypes = res;
    }));
  }

  private allOppCategoryTypes$(): Observable<OpportunityCategoryType[]> {
    return this._allOppCategoryTypes
      .watch()
      .valueChanges
      .pipe(
        map(result => {
          const item = (<any>result).data;
          return item ? (<any>item).opportunityCategoryTypes.data as OpportunityCategoryType[] : [];
        })
      );
  }

  ngOnInitHandleDropdowns(): Observable<OpportunityType[]> {
    let oppTypes$ = this.opportunityTypeService.getOpportunityTypes();

    return oppTypes$;
    // .subscribe(items => {
    //     this.populateOppTypeOptions(items);
    //   });
    // this.subscriptions$.push(oppTypes$);
  }

  private populateOppCategoryTypeOptions(categoryTypes: OpportunityCategoryType[]): void {
    this.opportunityCategoryTypeOptions = [];
    if (categoryTypes != null && categoryTypes.length > 0) {
      categoryTypes.map(c => {
        this.opportunityCategoryTypeOptions.push({ label: c.name, value: c.id });
      });
    }
  }

  private populateOppTypeOptions(opportunityTypes: OpportunityType[]): void {
    this.opportunityTypesOptions = [];
    if (opportunityTypes != null && opportunityTypes.length > 0) {
      opportunityTypes.map(t => {
        this.opportunityTypesOptions.push({ label: t.name, value: t.id });
      });
    }
  }

  private getAccountProfiles$() : Observable<Profile[]> {
    return this._allAccountProfiles
    .watch()
    .valueChanges
    .pipe(
      map(result => {
        const item = (<any>result).data;
        return item ? (<any>item).systemAccountProfiles.data as Profile[] : [];
        // return item ? (<any>item).accountProfiles.data as Profile[] : [];
      })
    );
  }

  public onOpportunityTypeChange(event: any) : void {
    let oppTypeIsClosed = this.isOpportunityTypeClosed();

    //show the dropdowns for creating a project only when the opportunity is selected as
    //closed
    if (oppTypeIsClosed) {
      this.landPurchaseClosedOppDropdownsInit();
      this.enableProjectFormDetails();
    } else {
      this.disableProjectFormDetails();
    }
  }

  private disableProjectFormDetails(): void {
    this.opportunityForm.get('project').disable();
  }

  private enableProjectFormDetails(): void {
    this.opportunityForm.get('project').enable();
  }

  private landPurchaseClosedOppDropdownsInit(): void {
    this.subscriptions$.push(
      this.getAccountProfiles$()
      .pipe(
        map(items => {
          return {
            accountProfiles: items
          };
        })
      )
      .subscribe(result => {
        this.populateAccountProfileOptions(result.accountProfiles);
      })
    );
  }

  private populateAccountProfileOptions(accountProfiles: Profile[]): void {
    this.allAccountProfiles = accountProfiles;
    this.accountProfileOptions = [];
    if (accountProfiles != null && accountProfiles.length > 0) {
      accountProfiles.map(acc => {
        this.accountProfileOptions.push({ label: `${acc.firstName} ${acc.lastName}`, value: acc.subjectId });
      });
    }
  }

  public onOpportunityCategoryTypeChange(event: any): void {
    let selectionCategoryTypeOption = this.opportunityCategoryTypeOptions.find(c => c.value == event.value).label;

    this.selectedCategoryType = this.CategoryTypeEnums[selectionCategoryTypeOption];

    switch (selectionCategoryTypeOption) {
      case "Land Purchases Opportunity":
        this.selectedCategoryType = 2;
      break;
      case "Sales Opportunity":
        this.selectedCategoryType = 1;
      break;
    }

    this.initForm();

    //the opportunity category type should always be visible to the user when creating a new opp
    // this.opportunityForm.get('opportunityCategoryTypeId').setValue(event.value);
  }

  private showLandPurchaseOpportunityFields(opp: LandPurchaseOpportunity): void {

    this.isProjectCreated = opp && opp.project ? opp.project.name != null : false;

    console.log("opp --> ", opp)

    this.opportunityForm = null;

    var control = new LandPurchaseOpportunityFormControl();

    control.id.setValue(opp ? opp.id : null);
    control.code.setValue(opp ? opp.code : null);
    control.details.setValue(opp ? opp.details : null);
    control.plotDescription.setValue(opp ? opp.plotDescription : null);
    control.sellingPrice.setValue(opp ? opp.sellingPrice : null);
    control.sellerId.setValue(opp && opp.seller ? opp.seller.id : null);

    control.address.controls.id.setValue(opp && opp.address ? opp.address.id : null);
    control.address.controls.addressLine1.setValue(opp && opp.address ? opp.address.addressLine1 : null);
    control.address.controls.addressLine2.setValue(opp && opp.address ? opp.address.addressLine2 : null);
    control.address.controls.addressLine3.setValue(opp && opp.address ? opp.address.addressLine3 : null);
    control.address.controls.addressLine4.setValue(opp && opp.address ? opp.address.addressLine4 : null);
    control.address.controls.postCode.setValue(opp && opp.address ? opp.address.postCode : null);
    control.address.controls.country.setValue(opp && opp.address ? opp.address.country : null);
    control.address.controls.locationLat.setValue(opp && opp.address ? opp.address.locationLat : null);
    control.address.controls.locationLng.setValue(opp && opp.address ? opp.address.locationLng : null);

    control.assignedUserAccountId.setValue(opp && opp.opportunity.assignedUserAccount ? opp.opportunity.assignedUserAccount.id : null);
    control.probability.setValue(opp && opp.opportunity ? opp.opportunity.probability : null);
    control.value.setValue(opp && opp.opportunity ? opp.opportunity.value : null);
    control.name.setValue(opp && opp.opportunity ? opp.opportunity.name : null);

    control.opportunityLostTypeId.setValue(opp && opp.opportunity.opportunityLostType ? opp.opportunity.opportunityLostType.id : null);
    control.lostTypeDescription.setValue(opp && opp.opportunity ? opp.opportunity.lostTypeDescription : null);

    control.project.controls.name.setValue(opp && opp.project ? opp.project.name : null);
    control.project.controls.code.setValue(opp && opp.project ? opp.project.code : null);
    control.project.controls.startDate.setValue(opp && opp.project.startDate ? new Date(opp.project.startDate) : null);
    control.project.controls.dueDate.setValue(opp && opp.project.dueDate ? new Date(opp.project.dueDate) : null);
    control.project.controls.projectManagerId.setValue(opp && opp.project.projectManager ? opp.project.projectManager.subjectId : null);

    control.dealTypeValues.controls.id.setValue(opp && opp.dealTypeValue ? opp.dealTypeValue.id : null);
    control.dealTypeValues.controls.barterValue.setValue(opp && opp.dealTypeValue ? opp.dealTypeValue.barterValue: null);
    control.dealTypeValues.controls.cashValue.setValue(opp && opp.dealTypeValue ? opp.dealTypeValue.cashValue : null);
    control.dealTypeValues.controls.partBarterValue.setValue(opp && opp.dealTypeValue ? opp.dealTypeValue.partBarterValue : null);
    control.dealTypeValues.controls.partCashValue.setValue(opp && opp.dealTypeValue ? opp.dealTypeValue.partCashValue : null);
    control.dealTypeValues.controls.dealTypeOpportunityId
      .setValue(opp && opp.dealTypeValue && opp.dealTypeValue.dealTypeOpportunity ? opp.dealTypeValue.dealTypeOpportunity.id : null);



    if(this.isEdit) {
      control.opportunityTypeId.setValue(opp && opp.opportunity.opportunityType ? opp.opportunity.opportunityType.id : null);
      control.opportunityTypeId.enable();

      if (opp.opportunity.opportunityType.id == 2) {
        this.landPurchaseClosedOppDropdownsInit();
      }

      debugger;
      if(opp.dealTypeValue && opp.dealTypeValue.dealTypeOpportunity) {
        this.dealTypeLabel = this.dealTypeOpportunityOptions.find(_=>_.value === opp.dealTypeValue.dealTypeOpportunity.id).label;
      }
    }
    else {
      control.opportunityTypeId.setValue(this.opportunityTypesOptions[0] ? this.opportunityTypesOptions[0].value : null);
    }

    this.opportunityForm = this.formBuilder.group(control);

    //keep watch on the locationLat to update the map accordingly
    this.opportunityForm.get('address').get('locationLat').valueChanges.subscribe(locationLat => {
      var location = { lat: locationLat , lng: this.marker.getPosition().lng() };
      if (this.gmap != null && this.gmap.map != null)
        this.gmap.map.panTo(location);
    });

    //keep watch on the locationLng to update the map accordingly
    this.opportunityForm.get('address').get('locationLng').valueChanges.subscribe(locationLng => {
      var location = { lat: this.marker.getPosition().lat() , lng: locationLng };
      if (this.gmap != null && this.gmap.map != null)
        this.gmap.map.panTo(location);
    });

    if (this.isOpportunityTypeClosed()) {
      this.enableProjectFormDetails();

      if (this.isEdit) {
        this.disableLandingPurchaseOppFormIfClosed();
      }
    } else {
      this.disableProjectFormDetails();
    }
  }

  //if the opportunityType is set as closed, then a project for this opportunity
  //has been created in ORCHESTRATE
  //the data pertaining the created project would need to be retrieved from ORCH?
  private disableLandingPurchaseOppFormIfClosed() {
    this.opportunityForm.get('opportunityTypeId').disable();
    this.opportunityForm.get('project').disable();

    this.isProjectCreated = true;
  }

  private showSalesOpportunityFields(): void {
    var control = new SalesOpportunityFormControl();

    control.name.setValue(this.opportunity ? this.opportunity.name : null);
    control.description.setValue(this.opportunity ? this.opportunity.description : null);
    control.productIds.setValue(this.opportunity.opportunityProducts ? this.opportunity.opportunityProducts.map(oP => oP.productId) : null);
    control.probability.setValue(this.opportunity ? this.opportunity.probability : null);
    control.value.setValue(this.opportunity ? this.opportunity.value : null);
    control.assignedContactId.setValue(this.opportunity ? this.opportunity.assignedContactId : null);
    control.assignedUserAccountId.setValue(this.opportunity ? this.opportunity.assignedContactId : null);
    control.opportunityLostTypeId.setValue(this.opportunity ? this.opportunity.opportunityLostTypeId : null);
    control.lostTypeDescription.setValue(this.opportunity ? this.opportunity.lostTypeDescription : null);

    control.opportunitySelectionModel.controls.mainCatIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.mainCatIds : null);
    control.opportunitySelectionModel.controls.subCatIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.subCatIds : null);
    control.opportunitySelectionModel.controls.regionIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.regionIds : null);
    control.opportunitySelectionModel.controls.localityIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.localityIds : null);
    control.opportunitySelectionModel.controls.dealTypeIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.dealTypeIds : null);
    control.opportunitySelectionModel.controls.startingBudget.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.startingBudget : null);
    control.opportunitySelectionModel.controls.maximumBudget.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.maximumBudget : null);
    control.opportunitySelectionModel.controls.minimumBedrooms.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumBedrooms : null);
    control.opportunitySelectionModel.controls.minimumGarages.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumGarages : null);
    control.opportunitySelectionModel.controls.minimumBathrooms.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumBathrooms : null);
    control.opportunitySelectionModel.controls.roomsIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.roomsIds : null);
    control.opportunitySelectionModel.controls.featureIds.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.featureIds : null);
    control.opportunitySelectionModel.controls.description.setValue(this.opportunity.opportunitySelectionModel ? this.opportunity.description : null);

    if(this.isEdit) {
      control.opportunityTypeId.enable();
      control.opportunityTypeId.setValue(this.opportunity ? this.opportunity.opportunityType.id : null);
    }
    else {
      control.opportunityTypeId.setValue(this.opportunityTypesOptions.length > 0 ? this.opportunityTypesOptions[0].value : null);
    }

    this.opportunityForm = this.formBuilder.group(control);

    this.validation = new AcquisitionOpportunityValidation(this);
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  setupForm() : Observable<any> {
    let initSetup$ = this.allOppCategoryTypes$()
    .pipe(
      map(result => {
        this.populateOppCategoryTypeOptions(result);
        return this.route.params;
      })
      ,switchMap((params: Params) => {
        const routeParams = params.value;

        const categoryType: string =  routeParams['categoryType'];
        const opportunityId: string = routeParams['id'];

        let selectedCategoryType: number = 0;

        this.isEdit = opportunityId != null; // - Returns true if an id was present within the url

        // switch(categoryType) {
        //   case "sales-opp":
        //     selectedCategoryType = this.CategoryTypeEnums["Sales Opportunity"];
        //     // this.onGetOpportunity(opportunityId);
        //   break;

        //   case "land-purchase-opp":
            selectedCategoryType = this.CategoryTypeEnums["Land Purchases Opportunity"];
          // break;
        // }

        return of({ opportunityId, selectedCategoryType });
      })
      ,switchMap(result => {
        if (result.selectedCategoryType && result.opportunityId) {
          this.selectedCategoryType = result.selectedCategoryType;

          switch(this.selectedCategoryType) {
            case 1:
              return this.onGetOpportunity$(parseInt(result.opportunityId))
                .pipe(
                  map(o=>{
                    return { opp: o, oppId: result.opportunityId }
                  })
                );
              break;
            case 2:
              return this.getLandPurchaseByOppId$(parseInt(result.opportunityId))
                .pipe(
                  map(o=>{
                    return { opp: o, oppId: result.opportunityId }
                  })
                );
            break;

            default:
              return of({ opp: null, oppId: null});
            break;
          }
        }

        return of({ opp: null, oppId: null});
      })
    );

    return initSetup$;
    // .subscribe(result => {
    //   switch(this.selectedCategoryType) {
    //     case 1:
    //       this.opportunity = result.opp as OpportunityExtended;
    //       this.onGetPropertyDetails(result.oppId);
    //     break;

    //     case 2:
    //       this.landPurchaseOpp = result.opp as LandPurchaseOpportunity;
    //     break;
    //   }


    //   this.initForm();
    // });

    // this.subscriptions$.push(initSetup$);
  }

  private getLandPurchaseByOppId$(id: number): Observable<LandPurchaseOpportunity> {
    return this._landPurchaseOpportunityByIdQuery
    .watch({id: id})
    .valueChanges
    .pipe(
      map(result => {
        const item = (<any>result).data;
        return item ? (<any>item).landPurchaseOpportunityByOppId.data as LandPurchaseOpportunity : new LandPurchaseOpportunity();
      })
    );
  }

  private getAllDealTypes(MUT_VARS?: {}): void {
    let dealTypes$ =
      this.getAllDealTypes$(MUT_VARS)
      .subscribe(result => {
        this.dealTypeOpportunityOptions = this.populateDealTypeOpportunityOptions(result);
      });

    this.subscriptions$.push(dealTypes$);
  }

  private getAllDealTypes$(MUT_VARS?: {}): Observable<IDealTypeOpportunity[]> {
    return this._getAllDealTypeOpportunties
    .watch(MUT_VARS)
    .valueChanges
    .pipe(
      map(result => {
        const ITEM = (<any>result).data;
        return ITEM ? (<any>ITEM).dealTypeOpportunities.data as IDealTypeOpportunity[] :  {} as IDealTypeOpportunity[];
      })
    );
  }

  private populateDealTypeOpportunityOptions(dealTypes: IDealTypeOpportunity[]): SelectItem[] {
    let options: SelectItem[] = [];

    if (dealTypes != null && dealTypes.length) {
      dealTypes.map(_ => {
        options.push({ label: _.name, value: _.id });
      });
    }

    return options;
  }

  initForm() {
    this.onGetUsers();
    this.onGetOpportunityLostTypes();

    this.selectedCategoryType = 2;

    switch(this.selectedCategoryType) {
      case 1:
        this.onGetMainCategories();
        this.onGetSubCategoriesOnLoad(this.opportunity.opportunitySelectionModel.mainCatIds,this.opportunity.opportunitySelectionModel.subCatIds);
        this.onGetPropertyLocalitiesOnLoad(this.opportunity.opportunitySelectionModel.regionIds,this.opportunity.opportunitySelectionModel.localityIds);
        this.onGetPropertyRegions();
        this.onGetPropertyDealType();
        this.onGetPropertyRooms();
        this.onGetPropertyFeatures();

        this.showSalesOpportunityFields();
      break;

      case 2:
        this.showLandPurchaseOpportunityFields(this.landPurchaseOpp);
        this.initGoogleMap();
      break;

      default:
        this.showSalesOpportunityFields();
      break;
    }


    //the category type can only be selected when creating a new opportunity
    // if (!this.isEdit) {
    //   this.opportunityForm.get('opportunityCategoryTypeId').enable();
    // }

    if (this.selectedCategoryType == this.CategoryTypeEnums["Sales Opportunity"]) {
      this.onGetProducts();

      if (this.isEdit && this.opportunity != null && this.opportunity.assignedContact) {

        let defaultValue = this.populateSelectedContactDetails(this.opportunity.assignedContact, this.selectedCategoryType);

        if (this.opportunityForm) {
          this.opportunityForm.patchValue({
            assignedContactId: defaultValue
          });
        }

        this.onGetQuotes(this.opportunity.id);
        this.onGetTasks(this.opportunity.id);
      }
    }
    else if (this.selectedCategoryType == this.CategoryTypeEnums["Land Purchases Opportunity"]) {
      if (this.isEdit && this.landPurchaseOpp != null && this.landPurchaseOpp.seller) {

        let defaultValue = this.populateSelectedContactDetails(this.landPurchaseOpp.seller, this.selectedCategoryType);

        if (this.opportunityForm) {
          this.opportunityForm.patchValue({
            sellerId: defaultValue
          });
        }
      }
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {

          this.contactService.getContact(+contactId).subscribe(
            (contact: Contact) => {
              // let defaultValue: SelectItem = {
              //   label: contact.fullName,
              //   value: +contactId
              // };

              // this.opportunityForm.patchValue({
              //   assignedContactId: defaultValue
              // });

              let labelText = contact.fullName.trim().length > 0 ? contact.fullName : "NO-NAME";
              let defaultValue: SelectItem = null;
              let landOppDefaultSelectItem: SelectItem = {
                label: labelText,
                value: contact.id
              };

              defaultValue = landOppDefaultSelectItem;

              if (this.opportunityForm) {
                this.opportunityForm.patchValue({
                  sellerId: defaultValue
                });
              }
            }
          );
        }
      }
    );

    this.onGetNotInterested(this.opportunity.id);
    this.isFormInit = true;
    this.validation = new AcquisitionOpportunityValidation(this);
  }

  private populateSelectedContactDetails(c: Contact, type: number): SelectItem {
    let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
    let defaultValue: SelectItem = null;

    switch(type) {
      case 1:
        c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
        c.companyName ? labelText += " / Company: " + c.companyName : "";
        c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
        c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
        c.email ? labelText += " / Email: " + c.email : "";

        let salesOppDefaultSelectItem: SelectItem = {
          label: labelText,
          value: this.opportunity.assignedContact.id
        };

        defaultValue = this.opportunity.assignedContact.id ? salesOppDefaultSelectItem : null;
      break;

      case 2:
        // c.organization ? labelText += " / Company: " + c.organization.name : "";
        // c.organization ? labelText += " / Mob: " + c.organization.mobilePhoneNumber : "";
        // c.organization ? labelText += " / Email: " + c.organization.email : "";

        let landOppDefaultSelectItem: SelectItem = {
          label: labelText,
          value: this.landPurchaseOpp.seller.id
        };

        defaultValue = this.landPurchaseOpp.seller.id ? landOppDefaultSelectItem : null;
      break;
    }

    return defaultValue;
  }

  isOpportunityTypeLost() {
    const oppTypeIdValue = this.opportunityForm.get('opportunityTypeId').value;
    const oppType = this.opportunityTypesOptions.find(o => o.value === oppTypeIdValue);

    if (oppType) {
      const oppTypeLabel = oppType.label;
      if (oppTypeLabel === PrepopulatedOpportunityTypes.Lost)
        return true;
    }

    return false;
  }

  isOpportunityTypeClosed() {
    const oppTypeIdValue = this.opportunityForm.get('opportunityTypeId').value;
    const oppType = this.opportunityTypesOptions.find(o => o.value === oppTypeIdValue);

    if (oppType) {
      const oppTypeLabel = oppType.label;
      if (oppTypeLabel === PrepopulatedOpportunityTypes.ClosedPurchase)
        return true;
    }

    return false;
  }

  onGetMainCategories() {
    this.propertyService.getMainCategoryTypes().subscribe(
      (mainCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        mainCategories.map(p => {
          this.MainCatOptions.push({ label: p.categoryName, value: p.categoryId });
        });
      }
    );
  }

  onGetSubCategories(SelectedMainCat: number[]) {
    this.propertyService.getSubCategoryTypes(SelectedMainCat).subscribe(
      (subCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        this.SubCatOptions = [];
        subCategories.map(p => {
          this.SubCatOptions.push({ label: p.categoryName, value: p.categoryId });
        });

        this.cleanSubCategoryValues(subCategories);
      }
    );
  }

  onGetSubCategoriesOnLoad(SelectedMainCat: number[], selectedSubCats: number[]) {
    this.propertyService.getSubCategoryTypes(SelectedMainCat).subscribe(
      (subCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        this.SubCatOptions = [];
        subCategories.map(p => {
          this.SubCatOptions.push({ label: p.categoryName, value: p.categoryId });
        });



        this.cleanSubCategoryValuesOnLoad(selectedSubCats);
      }
    );
  }

  cleanSubCategoryValuesOnLoad(subCategories: number[]) {

    this.opportunityForm.get('opportunitySelectionModel.subCatIds').setValue(subCategories);
  }

  cleanSubCategoryValues(subCategories: PropertyCategory[]) {
    // Get the current selected sub-categories
    var subCatValues = this.opportunityForm.get('opportunitySelectionModel.subCatIds').value;
    // Get the sub-categoriy ids retrieved from the database
    var subCatIds = subCategories.map(x => x.categoryId);

    var subCatToSelect = subCatIds;//subCatValues.filter(x => subCatIds.includes(x));
    this.opportunityForm.get('opportunitySelectionModel.subCatIds').setValue(subCatToSelect);
  }

  onGetPropertyLocalities(SelectedRegions: number[]) {
    this.propertyService.getPropertyLocalities(SelectedRegions).subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.PropertyLocalitiesOptions = [];
        propertyLocalities.map(p => {
          this.PropertyLocalitiesOptions.push({ label: p.localityName, value: p.localityId });
        });

        this.cleanLocalityValues(propertyLocalities);
      }
    );
  }

  cleanLocalityValues(propertyLocalities: PropertyLocality[]) {
    // Get the current selected localities from the form
    var locValues = this.opportunityForm.get('opportunitySelectionModel.localityIds').value;
    // Get the locality ids retrieved from the database
    var locIds = propertyLocalities.map(x => x.localityId);

    var locToSelect = locIds;//locValues.filter(x => locIds.includes(x));
    this.opportunityForm.get('opportunitySelectionModel.localityIds').setValue(locToSelect);
  }

  onGetPropertyLocalitiesOnLoad(SelectedRegions: number[],propertyLocaltyIds: number[]) {
    this.propertyService.getPropertyLocalities(SelectedRegions).subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.PropertyLocalitiesOptions = [];
        propertyLocalities.map(p => {
          this.PropertyLocalitiesOptions.push({ label: p.localityName, value: p.localityId });
        });

        this.cleanLocalityValuesOnLoad(propertyLocaltyIds);
      }
    );
  }

  cleanLocalityValuesOnLoad(propertyLocalities: number[]) {
    this.opportunityForm.get('opportunitySelectionModel.localityIds').setValue(propertyLocalities);
  }

  onGetPropertyRegions() {
    this.propertyService.getPropertyRegions().subscribe(
      (propertyRegions: PropertyRegion[]) => {
        // - Store the retrieved products as options for multiselect
        propertyRegions.map(p => {
          this.PropertyRegionsOptions.push({ label: p.regionName, value: p.regionId });
        });
      }
    );
  }

  onGetPropertyDealType() {
    this.propertyService.getPropertyDealTypes().subscribe(
      (propertyDealTypes: PropertyDealType[]) => {
        // - Store the retrieved products as options for multiselect
        propertyDealTypes.map(p => {
          this.PropertyDealTypesOptions.push({ label: p.dealTypeName, value: p.dealTypeId });
        });
      }
    );
  }

  onGetPropertyRooms() {
    this.propertyService.getPropertyRooms().subscribe(
      (propertyRooms: PropertyAttribute[]) => {
        // - Store the retrieved products as options for multiselect
        propertyRooms.map(p => {
          this.PropertyRoomsOptions.push({ label: p.attributeName, value: p.attributeId });
        });
      }
    );
  }

  onGetPropertyFeatures() {
    this.propertyService.getPropertyFeatures().subscribe(
      (propertyFeatures: PropertyAttribute[]) => {
        // - Store the retrieved products as options for multiselect
        propertyFeatures.map(p => {
          this.PropertyFeaturesOptions.push({ label: p.attributeName, value: p.attributeId });
        });
      }
    );
  }

  onPostPropertyDetails(oppSelection: OpportunitySelection) {
    this.propertyService.postPropertyDetails(oppSelection).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The property details has been created.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding property details", error);
      }
    );
  }

  onUpdatePropertyDetails(oppSelection: OpportunitySelection) {
    this.propertyService.updatePropertyDetails(oppSelection.opportunityId, oppSelection).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The property details has been updated.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating property details", error);
      }
    );
  }

  onGetPropertyDetails(id: number) {
    this.propertyService.getPropertyDetails(id).subscribe(
      (propertyOppSelection: PropertyOppSelection) => {
        // - Store the retrieved products as options for multiselect
        this.opportunity.opportunitySelectionModel = propertyOppSelection.oppSelections.oppSelectionModel;
        this.Properties = propertyOppSelection.properties;
        this.initForm();
      }
    );
  }

  onGetProducts() {
    this.productService.getProducts().subscribe(
      (products: Product[]) => {
        // - Store the retrieved products as options for multiselect
        products.map(p => {
          this.productsOptions.push({ label: p.name, value: p.id });
        });

        if (this.isEdit && this.opportunityForm) {
          this.opportunityForm.patchValue({
            productIds: this.opportunity.opportunityProducts.map(oP => oP.productId)
          });
        }
      }
    );
  }


  onGetQuotes(opportunityId: number){
    this.quoteService.getQuotesByOpportunityId(opportunityId).subscribe(
      (quotes: Quote[]) => {
        this.opportunity.quotes = quotes;
        quotes.forEach(element => {
          if(element.isSelectedInvoice){
            this.isQuoteClosed = true;
          }
        });
      }
    );
  }

  //-------- TASKS --------
  onGetTasks(opportunityId: number) {
    // Admins can see everyone's tasks
    // if (this.authService.isAdmin()) {
      this.taskService.getTasksByOpportunityId(opportunityId).subscribe(
        (tasks: Task[]) => {
          this.opportunity.tasks = tasks;
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    // }
  }

  onCompleteTask(task: Task) {
    let completedTask = this.opportunity.tasks.find(t => t.id === task.id);
    task.updatedByUserAccountId = this.authService.applicationProfileUser().id; // Actual task

    this.taskService.toggleTaskCompletion(task).subscribe(
      (response: Response) => {
        // NOTE: The update is made in the API!!!
        // Here I am setting the task to complete because the update was successful, so we want the change to be shown to the user
        completedTask.isTaskComplete = !completedTask.isTaskComplete;
        this.taskService.taskCounterChanged.next();
        this.taskService.tasksChanged.next();

        // Only ask for new comm. log if task is complete and is assigned to at least one contact
        if (task.contactTasks.length > 0 && completedTask.isTaskComplete)
          this.onAskForCommunicationLog(completedTask);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error completing task", error);
      }
    );
  }

  onAskForCommunicationLog(task: Task) {
    let firstContact = task.contactTasks[0].contact;

    this.confirmationService.confirm({
      header: 'Communication Log',
      message: 'The task has been marked as complete. Would you now like to create a new communication log for ' + firstContact.fullName + '?',
      accept: () => {
        this.router.navigate(['/contacts', firstContact.id, 'communication-history', 'new']);
      }
    });
  }

  rowStyle(task: Task, rowIndex: number) {
    if (task.isTaskComplete)
      return "bg-success";

    // Is Task Late?
    let reminderDate1 = new Date(task.reminderDate);
    let now = new Date();

    // If the task is not completed and the reminder date has already passed
    if (!task.isTaskComplete && now > reminderDate1)
      return "bg-danger";

    // Is Task For Today?
    let reminderDate = new Date(task.reminderDate).setHours(0, 0, 0, 0);
    let todayDate = new Date().setHours(0, 0, 0, 0);

    let reminderDateTime = new Date(task.reminderDate);
    let todayDateTime = new Date();

    // If the task is not completed and the reminder date is set for today (but time has not yet passed)
    if (!task.isTaskComplete && (todayDate === reminderDate && todayDateTime <= reminderDateTime))
      return "bg-warning";

    return "";
  }

  onDeleteTask(task: Task) {
    this.confirmationService.confirm({
      header: 'Delete Task',
      message: 'Are you sure you want to delete this task?',
      accept: () => {
        task.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.taskService.deleteTask(task).subscribe(
          (response: Response) => {
            this.onGetTasks(this.opportunity.id);
            // this.taskService.tasksChanged.next();
            this.taskService.taskCounterChanged.next();
            this.toastService.createSuccessMessage("Success", "The task has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting task", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the task has been aborted.");
      }
    });
  }


  //-------- END TASKS --------

  onGetOpportunity$(id: number): Observable<Opportunity> {
    return this.opportunityService.getOpportunity(id)
    .pipe(
      map((result: OpportunityExtended) => {
        return result;
      })
    );
  }


  onGetOpportunityLostTypes() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        // Store retrieved opportunity types in the opportunityLostTypesOptions select list
        opportunityLostTypes.map(cT => this.opportunityLostTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = null;

        if (this.isEdit) {
          if (this.selectedCategoryType == this.CategoryTypeEnums["Sales Opportunity"])
            defaultValue = this.opportunity.opportunityLostType ? this.opportunity.opportunityLostTypeId : null;
          else if (this.selectedCategoryType == this.CategoryTypeEnums["Land Purchases Opportunity"])
            defaultValue = this.landPurchaseOpp.opportunity.opportunityLostType ? this.landPurchaseOpp.opportunity.opportunityLostType.id : null;
        }
        if (this.opportunityForm) {
          this.opportunityForm.patchValue({
            opportunityLostTypeId: defaultValue
          });
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost opportunity types", error);
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else {
          if (this.selectedCategoryType == this.CategoryTypeEnums["Sales Opportunity"])
            defaultValue = this.opportunity.assignedUserAccountId ? this.opportunity.assignedUserAccountId : this.authService.applicationProfileUser().id;
          else if (this.selectedCategoryType == this.CategoryTypeEnums["Land Purchases Opportunity"])
            defaultValue = this.landPurchaseOpp.opportunity.assignedUserAccount ? this.landPurchaseOpp.opportunity.assignedUserAccount.id : this.authService.applicationProfileUser().id;
        }
        if (this.opportunityForm) {
          this.opportunityForm.patchValue({
            assignedUserAccountId: defaultValue
          });
        }
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let organization = cT["organization"];
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          let labelText =
          cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.assignedContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetViewings(contactId: number) {
    this.opportunityService.getViewingsByContactId(contactId).subscribe(
      (viewed: number[]) => {
        this.Viewings = viewed;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving viewed properties", error);
      }
    );
  }

  onGetNotInterested(oppId: number) {
    this.opportunityService.getPropertiesNotInterested(oppId).subscribe(
      (notInterested: number[]) => {
        this.PropNotInterested = notInterested;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving not interested properties", error);
      }
    );
  }

  onAddOpportunity(newOpportunity: Opportunity, event) {
    this.opportunityService.addOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        let oppSelection = new OpportunitySelection();
        oppSelection.opportunityId = (<any>response).id;
        oppSelection.oppSelectionModel = newOpportunity.opportunitySelectionModel;
        this.onPostPropertyDetails(oppSelection);
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been created.");

        this.router.navigate(['opportunities', 'acquisition-opp', oppSelection.opportunityId, 'edit']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding opportunity", error);
      }
    );
  }

  private quoteModal(response: any, event: any, oppSelection: OpportunitySelection): void {
    this.confirmationService.confirm({
      header: 'New Quote',
      message: 'Would you like to create a quote for this opportunity?',
      accept: () => {
        this.router.navigate(['/opportunities','quote',(<any>response).id, 'new']);
      },
      reject: () => {
        if (event == "ToList"){
          this.router.navigate(['/opportunities/']);
        }
        else if (event == "Refresh") {
          this.router.navigate(['opportunities', 'acquisition-opp', oppSelection.opportunityId, 'edit']);
        }
      }
    });
  }

  onChangeMainCatogory() {
    var selectedMainCat = this.opportunityForm.get('opportunitySelectionModel.mainCatIds').value;
    if (selectedMainCat != null && selectedMainCat.length > 0) {
      this.onGetSubCategories(selectedMainCat);

      this.opportunityForm.get('opportunitySelectionModel.subCatIds').enable();
    }
    else {
      this.opportunityForm.get('opportunitySelectionModel.subCatIds').disable();
    }
  }

  onChangeRegion() {
    var selectedReg = this.opportunityForm.get('opportunitySelectionModel.regionIds').value;
    if (selectedReg != null && selectedReg.length > 0) {
      this.onGetPropertyLocalities(selectedReg);

      this.opportunityForm.get('opportunitySelectionModel.localityIds').enable();
    }
    else {
      this.opportunityForm.get('opportunitySelectionModel.localityIds').disable();
    }

  }

  onUpdateOpportunity(newOpportunity: Opportunity, event) {

    this.opportunityService.updateOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        let oppSelection = new OpportunitySelection();
        oppSelection.opportunityId = newOpportunity.id;
        oppSelection.oppSelectionModel = newOpportunity.opportunitySelectionModel;
        this.onUpdatePropertyDetails(oppSelection);
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been updated.");

        if (event == "ToList"){
          this.router.navigate(['/opportunities/']);
        }
        else if (event == "Refresh") {
          window.location.reload();
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating opportunity", error);
      }
    );
  }

  onSubmit(event) {
    if (this.selectedCategoryType == this.CategoryTypeEnums["Sales Opportunity"]) {
      this.submitSalesOpportunity();
    }
    else if (this.selectedCategoryType == this.CategoryTypeEnums["Land Purchases Opportunity"]) {
      this.opportunityForm = this.formBuilder.sanitizeFormValues(this.opportunityForm);

      this.submitLandPurchaseOpportunity();
    }
  }

  private populateProjectData(): void {
    let project = this.opportunityForm.get('project') as FormGroup;

    let plotDescription = this.opportunityForm.get('plotDescription');
    let details = this.opportunityForm.get('details');
    let address = this.opportunityForm.get('address') as FormGroup;
    let sellingPrice = this.opportunityForm.get('sellingPrice');

    address.removeControl("id");

    project.get('proposalDescription').setValue(`${plotDescription.value} ${details.value}`);

    project.get('address').setValue(address.getRawValue());
    project.get('sellingPrice').setValue(sellingPrice.value);
  }

  private submitSalesOpportunity(): void {
    let newOpportunity = this.formBuilder.sanitizeFormValues(this.opportunityForm).getRawValue();

    // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newOpportunity.assignedContactId && newOpportunity.assignedContactId.value)
      newOpportunity.assignedContactId = newOpportunity.assignedContactId.value;
    else
      newOpportunity.assignedContactId = null;

    newOpportunity.opportunityProducts = this.populateOpportunityProducts(newOpportunity.productIds);

    if (this.isEdit) {
      newOpportunity.id = this.opportunity.id; // - Set id of edited opportunity to its original id, same with externalId and createdById
      newOpportunity.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateOpportunity(newOpportunity, event);
    }
    else {
      newOpportunity.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddOpportunity(newOpportunity, event);
    }
  }



  private submitLandPurchaseOpportunity(): void {
    if (this.opportunityForm.valid) {
      this.opportunityForm = this.formBuilder.sanitizeFormValues(this.opportunityForm);

      const MUT_VARS = {
        item: this.opportunityForm.getRawValue()
      };


      //the dropdown used is storing it as {label, value} object
      MUT_VARS.item.sellerId =  MUT_VARS.item.sellerId.value;

      //if we are create a new land purchase opportunity, we don't have to send data for the project
      MUT_VARS.item.project = null;

      MUT_VARS.item.opportunityCategoryTypeId = this.categoryTypes[0].id;

      let dealTypeValueInput: {} = {
        cashValue: MUT_VARS.item.dealTypeValues.cashValue,
        barterValue: MUT_VARS.item.dealTypeValues.barterValue,
        partCashValue: MUT_VARS.item.dealTypeValues.partCashValue,
        partBarterValue: MUT_VARS.item.dealTypeValues.partBarterValue,
        landPurchaseOpportunityId: MUT_VARS.item.id,
        dealTypeOpportunityId: MUT_VARS.item.dealTypeValues.dealTypeOpportunityId
      };


      //the dealTypeValue input will hold the cash values, landOppId and the dealTypeOppId as well.
      MUT_VARS.item.dealTypeValueInput = dealTypeValueInput;
      delete MUT_VARS.item.dealTypeValues;

      debugger;

      if (!this.isEdit)
      {

        let mutation$ = this._createLandPurchaseOpportunityMutation.mutate(MUT_VARS);

        this.subscriptions$.push(
          mutation$
          .pipe(
            map(result => {
              const item = (<any>result).data;
              return item ? (<any>item).createLandPurchaseOpportunity.data as LandPurchaseOpportunity : new LandPurchaseOpportunity();
            })
          )
          .subscribe((createdLandPurchaseOpportunity: LandPurchaseOpportunity) => {
            this.toastService.createSuccessMessage("Success", `Land Purchase Opportunity ${createdLandPurchaseOpportunity.code} has been successfully created!`);
            this.router.navigate(["/opportunities", "list"]);
          })
        );
      }

      else {

        const MUT_VARS = {
          item: this.opportunityForm.getRawValue()
        };

        MUT_VARS.item.sellerId =  MUT_VARS.item.sellerId.value;

        if (this.isOpportunityTypeClosed()) {

          if (this.isProjectCreated === false) {
            this.populateProjectData();

            let selectedProjectManager = this.opportunityForm.get('project').get('projectManagerId');

            let projectManagerData = this.allAccountProfiles.find(a => a.subjectId === selectedProjectManager.value);

            let projectManager = {
              subjectId: projectManagerData.subjectId,
              firstName: projectManagerData.firstName,
              lastName: projectManagerData.lastName
            };


            MUT_VARS.item.project = this.opportunityForm.get('project').value

            delete MUT_VARS.item.project.projectManagerId;

            MUT_VARS.item.project.projectManager = projectManager;

            console.log("CLOSED --> ", MUT_VARS);
          }

          else {
            MUT_VARS.item.project = null;
          }

        }
        else {
          MUT_VARS.item.project = null;
          console.log("NOT CLOSED --> ", MUT_VARS);
        }



        let dealTypeValueInput: {} = {
          id: MUT_VARS.item.dealTypeValues.id,
          cashValue: MUT_VARS.item.dealTypeValues.cashValue,
          barterValue: MUT_VARS.item.dealTypeValues.barterValue,
          partCashValue: MUT_VARS.item.dealTypeValues.partCashValue,
          partBarterValue: MUT_VARS.item.dealTypeValues.partBarterValue,
          landPurchaseOpportunityId: MUT_VARS.item.id,
          dealTypeOpportunityId: MUT_VARS.item.dealTypeValues.dealTypeOpportunityId
        };


        //the dealTypeValue input will hold the cash values, landOppId and the dealTypeOppId as well.
        MUT_VARS.item.dealTypeValueInput = dealTypeValueInput;
        delete MUT_VARS.item.dealTypeValues;

        let mutation$ = this._updateLandPurchaseOpportunityMutation.mutate(MUT_VARS);

        this.subscriptions$.push(
          mutation$
          .pipe(
            map(result => {
              const item = (<any>result).data;
              return item ? (<any>item).updateLandPurchaseOpportunity.data as LandPurchaseOpportunity : new LandPurchaseOpportunity();
            })
          )
          .subscribe((updatedLandPurchaseOpportunity: LandPurchaseOpportunity) => {
            this.landPurchaseOpp = updatedLandPurchaseOpportunity;

            if (this.isOpportunityTypeClosed()) {
              this.disableLandingPurchaseOppFormIfClosed();
            }

            this.toastService.createSuccessMessage("Success", `Land Purchase Opportunity ${updatedLandPurchaseOpportunity.code} successfully updated!`);
            this.router.navigate(["/opportunities", "list"]);
          })
        );
      }
    }
    else {
      this._formUtil.markFormControlsAsTouched(this.opportunityForm);
    }
  }

  populateOpportunityProducts(assignedProductIds: number[]) {
    let opportunityProducts: {}[] = [];

    if (assignedProductIds != null && assignedProductIds.length > 0) {
      for (let id of assignedProductIds) {
        let opportunityProduct = {
          productId: id,
          opportunityId: this.opportunity.id,
          createdByUserAccountId: this.authService.applicationProfileUser().id,
        };
        opportunityProducts.push(opportunityProduct);
      }
    }

    return opportunityProducts;
  }


  onRowRender(rowData){
    let rowFormGroup = rowData as Quote;
    if(rowFormGroup.isSelectedInvoice == true) return 'isSelectedInvoice';
    else return '';
  }

  onProjectStartDateChange(event: any): void {
    let projectStartDate = this.opportunityForm.get('project').get('startDate');
    let projectDueDate = this.opportunityForm.get('project').get('dueDate');

    if (event != null) {
      projectDueDate.enable();

      this.minDueDate = new Date(event);

      //if start date is gt the due date, reset it so that a waning message is showm
      if (projectDueDate.value != null) {
        if (new Date(projectStartDate.value).getTime() > new Date(projectDueDate.value).getTime()) {
          projectDueDate.setValue(null);
          projectDueDate.markAsTouched();
        }
      }
    }
  }

  onDealTypeOpportunityChange(event: any): void {
    this.dealTypeLabel = this.dealTypeOpportunityOptions.find(_=>_.value === event.value).label;
  }


  //google maps
  initGoogleMap() {
    let lat = this.isEdit ?
      this.landPurchaseOpp.address.locationLat :
      GoogleMaps.DefaultLocationLat;

    let lng = this.isEdit ?
      this.landPurchaseOpp.address.locationLng :
      GoogleMaps.DefaultLocationLng;

    let location = this.isEdit ?
      this.landPurchaseOpp.address.addressLine1 :
      GoogleMaps.DefaultLocation;

    if (lat && lng) {
      this.options = {
        center: { lat: lat, lng: lng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        this.marker = new google.maps.Marker(
          {
            position: { lat: lat, lng: lng },
            title: location,
            animation: google.maps.Animation.DROP
          }
        )
      ];

      this.opportunityForm.patchValue({
        address: {
          addressLine1: location,
          locationLat: lat,
          locationLng: lng
        }
      });
    } else {
      this.options = {
        center: { lat: GoogleMaps.DefaultLocationLat, lng: GoogleMaps.DefaultLocationLng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        new google.maps.Marker(
          {
          }
        )
      ];


    }
  }

  initGoogleMapSearch() {
    if (!this.isGoogleMapsSearchInit) {
      let input = this.gMapsSearchElementRef.nativeElement;

      let options = {
        componentRestrictions: { country: "mt" },
        RankBy: 'PROMINENCE'
      };

      let autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace();
        this.onMapPlaceChanged(place);
      });

      this.isGoogleMapsSearchInit = true;
    }
  }

  onMapPlaceChanged(place) {
    if(place.address_components) {
      let streetnumberItem = place.address_components.find(x => x.types[0] === 'street_number');
      let streetItem = place.address_components.find(x => x.types[0] === 'route');
      let localityItem = place.address_components.find(x => x.types[0] === 'locality');
      let countryItem = place.address_components.find(x => x.types[0] === 'country');

      this.opportunityForm.patchValue({
        addressLine1: streetnumberItem ? streetnumberItem.long_name : "",
        addressLine3: streetItem ? streetItem.long_name : "",
        addressLine4: localityItem ? localityItem.long_name : "",
        country: countryItem ? countryItem.long_name : "",
      });

    }

    if (place.geometry) {
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
     let locationName = place.formatted_address;

      this.overlays = [
        this.marker = new google.maps.Marker(
          {
            title: locationName,
            position: { lat: lat, lng: lng },
            animation: google.maps.Animation.DROP
          }
        )
      ];
      this.gmap.map.panTo({ lat: lat, lng: lng });
      this.opportunityForm.patchValue({
        address: {
          addressLine3: locationName,
          locationLat: lat,
          locationLng: lng
        }
      });
    }
    else {
      this.overlays = [
        this.marker = new google.maps.Marker(
          {
          }
        )
      ];
      this.opportunityForm.patchValue({
        address: {
          locationLat: null,
          locationLng: null
        }
      });
    }

    this.ref.detectChanges();
  }

  handleMapClick(event){
    var location = event.latLng;

    if (this.marker) {
      this.marker.setPosition(location);
    } else {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.gmap.map
      });
    }

    this.gmap.map.panTo(location);


    let address$ = this.geocodeLatLng(location)
    .subscribe(result => {
      if (result.length === 3) {
        this.opportunityForm.patchValue({
          address: {
            addressLine2: result[0].long_name, //street name
            addressLine3: result[1].long_name , //locality,
            country: result[2].long_name , //country,

            locationLat: location.lat(),
            locationLng: location.lng()
          }
        });
      }
      else if (result.length === 4) {
        this.opportunityForm.patchValue({
          address: {
            addressLine2: result[0].long_name + ", " + result[1].long_name, //street name
            addressLine3: result[2].long_name, //locality,
            country: result[3].long_name , //country,

            locationLat: location.lat(),
            locationLng: location.lng()
          }
        });
      }
    });

    this.subscriptions$.push(address$);
  }

  geocodeLatLng(location: any) : Observable<any[]> {

    return new Observable((observer) => {
      const geocoder = new google.maps.Geocoder;

      const latlng = {lat: location.lat(), lng: location.lng()};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK') {
          if (results[0]) {
            const address = results[0].address_components
            observer.next(address);
            observer.complete();
          } else {
            observer.error("No result found");
            observer.complete();
          }
        } else {
          observer.error("Geocoder failed due to: " + status);
          observer.complete();
        }
      });
    });

  }
}
