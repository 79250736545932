import { Injectable } from '@angular/core';
import { Response, URLSearchParams, Http } from "@angular/http";

import { Contact } from "../models/contact.model";
import { ContactSource } from "../models/contact-source.model";
import { ContactType } from "../models/contact-type.model";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { ApiEndPoints, CustomConfigurations } from "app.constant";
import { ContactsSegment } from 'app/views/shared/models/contacts-segment.model';
import { AppSettings } from 'app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ContactProperty } from '../models/contact-property.model';
import { ContactSearchItem } from '../models/contact-search-item.model';


@Injectable()
export class ContactService {
  private contactsUrl: string = AppSettings.API_ENDPOINT + 'api/contacts/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getContactsCount(): Observable<number> {
    //this.authService.reauthenticateIfTokenExpired();

    let getContactsUrl = this.contactsUrl + "Count";

    if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
      getContactsUrl += "?assignedUserAccountId=" + this.authService.applicationProfileUser().id;

    this.loadingService.displayLoadingSpinner();
    return this.http.get(getContactsUrl).map(
      (response: Response) => {
        let contactsCount: number = (<any>response);
        return contactsCount;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // getLeadsCount(type: number): Observable<number> {
  //   //this.authService.reauthenticateIfTokenExpired();



  //   let getContactsUrl = this.contactsUrl + "LeadsCount";
  //   getContactsUrl += "?Type=" + type;

  //   if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
  //     getContactsUrl += "&assignedUserAccountId=" + this.authService.applicationProfileUser().id;

  //   this.loadingService.displayLoadingSpinner();
  //   return this.http.get(getContactsUrl).map(
  //     (response: Response) => {
  //       let count: number = (<any>response);
  //       return count;
  //     }
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }

  getLeadsConvertedThisMonthCount(type: number): Observable<number> {
    //this.authService.reauthenticateIfTokenExpired();

    let getContactsUrl = this.contactsUrl + "LeadsConvertedThisMonthCount";
    getContactsUrl += "?Type=" + type;

    if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
      getContactsUrl += "&assignedUserAccountId=" + this.authService.applicationProfileUser().id;

    this.loadingService.displayLoadingSpinner();
    return this.http.get(getContactsUrl).map(
      (response: Response) => {
        let count: number = (<any>response);
        return count;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getContactsSegment(params: HttpParams, showLoading: boolean = true): Observable<ContactsSegment> {
    //this.authService.reauthenticateIfTokenExpired();

    let getContactsUrl = this.contactsUrl;

    if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
      params = params.append("assignedUserAccountId", this.authService.applicationProfileUser().id.toString())

    if (showLoading)
      this.loadingService.displayLoadingSpinner();

    return this.http.get(getContactsUrl, { params: params }).map(
      (response: Response) => {
        let contacts: Contact[] = (<any>response).contacts;
        contacts = contacts.map(
          c => {
            // c.createdByUserAccount = this.userService.getUser(c.createdByUserAccountId);
            // c.updatedByUserAccount = this.userService.getUser(c.updatedByUserAccountId);
            // c.assignedUser = this.userService.getUser(c.assignedUserAccountId);
            return c;
          }
        );

        let contactsSegment: ContactsSegment = new ContactsSegment();
        contactsSegment.contacts = contacts;
        contactsSegment.totalContactsCount = (<any>response).totalContactsCount;

        return contactsSegment;
      }
    ).finally(() => {
      if (showLoading)
        this.loadingService.hideLoadingSpinner();
    });
  }

  getContactsSearch(searchTerm: string, showLoading: boolean = true): Observable<ContactSearchItem[]> {
    searchTerm = searchTerm.replace("/", " "); // - Replace '/' chars as route will change and cause issues!!

    //this.authService.reauthenticateIfTokenExpired();

    let getContactsUrl = this.contactsUrl + "Search";
    getContactsUrl += "?SearchTerm=" + searchTerm;

    if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
      getContactsUrl += "&assignedUserAccountId=" + this.authService.applicationProfileUser().id;

    if (showLoading)
      this.loadingService.displayLoadingSpinner();

    return this.http.get(getContactsUrl).map(
      (response: Response) => {
        let contacts: ContactSearchItem[] = (<any>response);
        contacts = contacts.map(
          c => {
            // c.createdByUserAccount = this.userService.getUser(c.createdByUserAccountId);
            // c.updatedByUserAccount = this.userService.getUser(c.updatedByUserAccountId);
            // c.assignedUser = this.userService.getUser(c.assignedUserAccountId);
            return c;
          }
        );
        return contacts;
      }
    ).finally(() => {
      if (showLoading)
        this.loadingService.hideLoadingSpinner();
    });
  }

  getContact(id: number): Observable<Contact> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.contactsUrl + id).map(
      (response: Response) => {
        let contact: Contact = (<any>response);
        // contact.createdByUserAccount = this.userService.getUser(contact.createdByUserAccountId);
        // contact.updatedByUserAccount = this.userService.getUser(contact.updatedByUserAccountId);
        // contact.assignedUser = this.userService.getUser(contact.assignedUserAccountId);
        return contact;
      }
    );
  }

  // getOpportunityContacts(): Observable<Contact[]> {
  //   return this.http.get(this.contactsUrl + "GetOpportunityContacts").map(
  //     (response: Response) => {
  //       let contacts: Contact[] = (<any>response);
  //       // contact.createdByUserAccount = this.userService.getUser(contact.createdByUserAccountId);
  //       // contact.updatedByUserAccount = this.userService.getUser(contact.updatedByUserAccountId);
  //       // contact.assignedUser = this.userService.getUser(contact.assignedUserAccountId);
  //       return contacts;
  //     }
  //   );
  // }

  getContactProperty(id: number): Observable<ContactProperty> {


    return this.http.get(this.contactsUrl + 'GetContactProperty/' + id).map(
      (response: Response) => {
        let contactProp: ContactProperty = (<any>response);
        return contactProp;
      }
    );
  }

  getOpportunityContacts(): Observable<Contact[]> {
    return this.http.get(this.contactsUrl + "GetOpportunityContacts").map(
      (response: Response) => {
        let contacts: Contact[] = (<any>response);
        // contact.createdByUserAccount = this.userService.getUser(contact.createdByUserAccountId);
        // contact.updatedByUserAccount = this.userService.getUser(contact.updatedByUserAccountId);
        // contact.assignedUser = this.userService.getUser(contact.assignedUserAccountId);
        return contacts;
      }
    );
  }

  addContact(contact: Contact): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.contactsUrl,
      contact
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateContact(contact: Contact): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.contactsUrl + contact.id,
      contact
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteContact(contact: Contact): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.contactsUrl + "delete/" + contact.externalId,
      contact
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  erasureContact(contact: Contact): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.contactsUrl + "erasure/" + contact.externalId,
      contact
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }


  //** THIS WAS EVIL! DON'T GET ALL CONTACTS TOGETHER EVER AGAIN! */
  // getContacts(): Observable<Contact[]> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   let getContactsUrl = this.contactsUrl;

  //   if (!CustomConfigurations.SharedContacts && !this.authService.isAdmin())
  //     getContactsUrl += "AssignedUser/" + this.authService.getUser().id;

  //   this.loadingService.displayLoadingSpinner();
  //   return this.http.get(getContactsUrl).map(
  //     (response: Response) => {
  //       let contacts: Contact[] = (<any>response);
  //       contacts = contacts.map(
  //         c => {
  //           c.createdByUserAccount = this.userService.getUser(c.createdByUserAccountId);
  //           c.updatedByUserAccount = this.userService.getUser(c.updatedByUserAccountId);
  //           c.assignedUser = this.userService.getUser(c.assignedUserAccountId);
  //           return c;
  //         }
  //       );
  //       return contacts;
  //     }
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }
}
