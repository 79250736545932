import { Contact } from "./contact.model";
import { Task } from "./task.model";
import { CrmUser } from "./user.model";
import { OpportunityProgress } from "./opportunity-progress.model";
import { OpportunityType } from "./opportunity-type.model";
import { OpportunityLostType } from "./opportunity-lost-type.model";
import { OpportunityProduct } from "./opportunity-product.model";
import { Quote } from './quote.model';
import { PropertyDetails } from './property-details.model';
import { OpportunityCategoryType } from './opportunity-category-type/opportunity-category-type.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Address } from './address.model';
import { Profile } from 'app/shared/profile/_models/profile.model';
import { IDealTypeOpportunity, IDealTypeValue } from "./deal-type-opportunity.model";

export class Opportunity {
  constructor(
        public id: number = 0,
        public opportunityProducts: OpportunityProduct[] = [],
        public opportunityProgress: OpportunityProgress[] = [],
        public opportunityLostTypeId: number = null,
        public opportunityLostType: OpportunityLostType = new OpportunityLostType(),
        public lostTypeDescription: string = null,
        public assignedContactId: number = null,
        public assignedContact: Contact = new Contact(),
        public assignedUserAccountId: number = null,
        public assignedUser: CrmUser = new CrmUser(),
        public opportunityTypeId: number = null,
        public opportunityType: OpportunityType = new OpportunityType,
        public name: string = null,
        public description: string = null,
        public uniqueId: string = null, // - This is a unique product ID inputted by the user, nullable, Eg: REMS would input Propert Ref Code.
        public probability: number = null,
        public value: number = null,
        public closedDate: string = null,
        public lostDate: string = null,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number = null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null,
        public quotes: Quote[] = [],
        public opportunitySelectionModel: PropertyDetails = new PropertyDetails,
        public tasks: Task[] = [],
    ) { }
}

export class OpportunityExtended extends Opportunity {
  constructor(
    public opportunityCategoryType: OpportunityCategoryType = new OpportunityCategoryType(),
    public assignedUserAccount: Profile = new Profile()
  )
  {
    super();
  }
}

export class BasicProject {
  constructor(
    public name: string = null,
    public code: string = null,
    public startDate: Date = null,
    public dueDate: Date = null,
    public projectManager: Profile = new Profile()
  ) {}
}

export class LandPurchaseOpportunity {
  constructor(
    public id: string = null,
    public code: string = null,
    public details: string = null,
    public plotDescription: string = null,
    public sellingPrice: number = null,
    public address: Address = new Address(),
    public seller: Contact = new Contact(),
    public opportunity: OpportunityExtended = new OpportunityExtended(),
    public project: BasicProject = new BasicProject(),
    public dealTypeValue: IDealTypeValue = {} as IDealTypeValue
  ) { }
}

export class SalesOpportunityFormControl {
  constructor(
    public name: FormControl = new FormControl(null, [Validators.required]),
    public productIds: FormControl = new FormControl(null),
    public description: FormControl = new FormControl(null),
    public probability: FormControl = new FormControl(null, [Validators.min(0), Validators.max(100)]),
    public value: FormControl = new FormControl(null),
    public assignedContactId: FormControl = new FormControl(null, [Validators.required]),
    public assignedUserAccountId: FormControl = new FormControl(null),
    public opportunityTypeId: FormControl = new FormControl({value: null, disabled: true}, [Validators.required]),
    public opportunityLostTypeId: FormControl = new FormControl(null),
    public lostTypeDescription: FormControl = new FormControl(null),
    public opportunityCategoryTypeId: FormControl = new FormControl({value: null, disabled: true}, [Validators.required]),

    public opportunitySelectionModel: FormGroup = new FormGroup({
      mainCatIds: new FormControl([]),
      subCatIds: new FormControl([]),
      regionIds: new FormControl([]),
      localityIds: new FormControl([]),
      dealTypeIds: new FormControl([]),
      startingBudget: new FormControl(null),
      maximumBudget: new FormControl(null),
      minimumBedrooms: new FormControl(null),
      minimumGarages: new FormControl(null),
      minimumBathrooms: new FormControl(null),
      roomsIds: new FormControl([]),
      featureIds: new FormControl([]),
      description: new FormControl(null),
      propertyReference: new FormControl(null),
    })
  )
  { }
}

export class LandPurchaseOpportunityFormControl {
  constructor(
    public id: FormControl = new FormControl({value: null, disabled: true}),
    public code: FormControl = new FormControl({value: null, disabled: true}),
    public details: FormControl = new FormControl(null),
    //this is made as required since it is also being used as the description for the opportunity (which is required in the opportunitie table :( )
    public plotDescription: FormControl = new FormControl(null),
    public sellingPrice: FormControl = new FormControl(null),
    public address: FormGroup = new FormGroup({
      id: new FormControl({value: null, disabled: true}),
      addressLine1: new FormControl(null),
      addressLine2: new FormControl(null),
      addressLine3: new FormControl(null),
      addressLine4: new FormControl(null),
      postCode: new FormControl(null),
      country: new FormControl(null),
      locationLat: new FormControl(null),
      locationLng: new FormControl(null)
    }),

    public sellerId: FormControl = new FormControl(null, [Validators.required]),
    public assignedUserAccountId: FormControl = new FormControl(null),
    public name: FormControl = new FormControl(null, [Validators.required]),
    public opportunityTypeId: FormControl = new FormControl({value: null, disabled: true}, [Validators.required]),
    public probability: FormControl = new FormControl(null, [Validators.min(0), Validators.max(100)]),
    public value: FormControl = new FormControl(null),
    public opportunityLostTypeId: FormControl = new FormControl(null),
    public lostTypeDescription: FormControl = new FormControl(null),

    //this data will be passed to ORCHESTRATE to create a project for this land purchase opportunity.
    public project: FormGroup = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required]),
      startDate: new FormControl(null, [Validators.required]),
      dueDate: new FormControl({value: null, disabled: true}, [Validators.required]),
      projectManagerId: new FormControl(null, [Validators.required]), // the project manager responsible
      proposalDescription: new FormControl(null),
      sellingPrice: new FormControl(null),

      address: new FormGroup({
        // id: new FormControl({value: null, disabled: true}),
        addressLine1: new FormControl(null),
        addressLine2: new FormControl(null),
        addressLine3: new FormControl(null),
        addressLine4: new FormControl(null),
        postCode: new FormControl(null),
        country: new FormControl(null),
        locationLat: new FormControl(null),
        locationLng: new FormControl(null)
      }),
    }),

    public dealTypeValues: FormGroup = new FormGroup({
      id: new FormControl(null),
      dealTypeOpportunityId: new FormControl(null),
      cashValue: new FormControl(null),
      barterValue: new FormControl(null),
      partCashValue: new FormControl(null),
      partBarterValue: new FormControl(null)
    })
  )
  { }
}
