import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { TaskService } from "../../shared/services/task.service";
import { UserService } from "../../shared/services/user.service";
import { ContactService } from "../../shared/services/contact.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { OpportunityService } from "../../shared/services/opportunity.service";
import { TaskTypeService } from '../../shared/services/task-type.service';
import { ConfirmationService } from "primeng/components/common/api";

import { Task } from "../../shared/models/task.model";
import { CrmUser } from "../../shared/models/user.model";
import { Contact } from "../../shared/models/contact.model";
import { ContactTask } from "../../shared/models/contact-task.model";
import { UserTask } from "../../shared/models/user-task.model";
import { Opportunity } from "../../shared/models/opportunity.model";
import { TaskType } from '../../shared/models/task-type.model';
import { SelectItem } from "primeng/components/common/api";
import { Observable } from "rxjs/Rx";
import { AutoComplete } from 'primeng/primeng';
import { PrepopulatedTaskTypes, GoogleMaps } from 'app.constant';
import { PropertyCodes } from 'app/views/shared/models/property-codes.model';
import { Property } from 'app/views/shared/models/property.model';
import { PropertyIdCode } from 'app/views/shared/models/property-id-codes.model';
import { combineLatest } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { SystemModeHelper, SYSTEM_KEYS } from 'app/views/shared/system-config.service';
import { System } from 'app/views/shared/system';

declare var google: any;

@Component({
  selector: 'app-task-manage',
  templateUrl: './task-manage.component.html',
  styleUrls: ['./task-manage.component.scss']
})
export class TaskManageComponent extends System implements OnInit {
  taskForm: FormGroup;                          // - Stores the task form's values
  isFormInit: boolean = false;                  // - The form is not created until it is initialised
  isEdit: boolean = false;                      // - The form is set to update a task if true and add a new task if false

  task: Task = new Task();                      // - Stores Task to populate form with a new / existing task's values

  taskTypesOptions: SelectItem[] = [];       // - Stores Task Type Dropdown Options
  _taskTypesOptions: SelectItem[] = [];
  usersOptions: SelectItem[] = [];              // - Stores Assign Users Dropdown Options
  contactsOptions: SelectItem[] = [];
  propertyOptions: SelectItem[] = [];            // - Stores Assign Contacts Dropdown Options
  opportunityOptions: SelectItem[] = [];        // - Stores opportunities of assigned contacts
  propCodeOptions: SelectItem[] = [];

  //** Google Maps */
  options: any;
  overlays: any[];
  isGoogleMapsSearchInit = false;
  @ViewChild("gMapsSearch") public gMapsSearchElementRef: ElementRef;
  @ViewChild("gmap") public gmap;

  //** DatePicker */
  minDate: Date = new Date(); // - Can't select a date before the current day
  dateRange: string = (this.minDate.getFullYear() - 50) + ':' + (this.minDate.getFullYear() + 50); // - 100 Year Range

  //** Spinner (Calendar event duration) */
  defaultCalendarDuration: number = 30; // - Default Duration
  minCalendarDuration: number = 5;      // - Duration in Minutes
  maxCalendarDuration: number = 600;
  isEmailGoingToSend: boolean = false;  // - Used to know if an Email is currently going to be sent (based on both send email checkbox values)
  contactsNoEmail: boolean = false;     // - Used to know if an Email was going to be sent to a Contact with no Email (For PrimeNG Confirm Dialog Box in HTML)

  isPropertySelected : boolean = false;
  Properties: Property[] = [];
  PropertySelected: Property = new Property();
  propParam: number  = 0;
  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;
  @ViewChild('propertiesAutoComplete') private propertiesAutoComplete: AutoComplete;

  public SYSTEM_KEYS : SYSTEM_KEYS = SYSTEM_KEYS;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private taskService: TaskService,
    private userService: UserService,
    private contactService: ContactService,
    private taskTypeService: TaskTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private opportunityService: OpportunityService,
    private ref: ChangeDetectorRef,
    private systemModeHelper: SystemModeHelper
  ) {
    super(systemModeHelper);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setupForm();

    console.log("Ryan's Keyyy -> " + this.systemKey);
  }

  getImageUrl(PropertySelected){
    let imagUrl = PropertySelected.propertyUrl + PropertySelected.propertyImageUrl;

    let link = 'url(' + imagUrl + ')';

    return link;
  }

  initGoogleMap() {
    let lat = this.isEdit ?
      this.task.locationLat :
      GoogleMaps.DefaultLocationLat;

    let lng = this.isEdit ?
      this.task.locationLng :
      GoogleMaps.DefaultLocationLng;

    let location = this.isEdit ?
      this.task.location :
      GoogleMaps.DefaultLocation;

    if (lat && lng) {
      this.options = {
        center: { lat: lat, lng: lng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        new google.maps.Marker(
          {
            position: { lat: lat, lng: lng },
            title: location,
            animation: google.maps.Animation.DROP
          }
        )
      ];

      this.taskForm.patchValue({
        location: location,
        locationLat: lat,
        locationLng: lng
      });
    } else {
      this.options = {
        center: { lat: GoogleMaps.DefaultLocationLat, lng: GoogleMaps.DefaultLocationLng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        new google.maps.Marker(
          {
          }
        )
      ];


    }
  }

  initGoogleMapSearch() {
    if (!this.isGoogleMapsSearchInit) {
      let input = this.gMapsSearchElementRef.nativeElement;

      let options = {
        componentRestrictions: { country: "mt" },
        RankBy: 'PROMINENCE'
      };

      let autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace();
        this.onMapPlaceChanged(place);
      });

      this.isGoogleMapsSearchInit = true;
    }
  }

  onMapPlaceChanged(place) {
    if (place.geometry) {
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      let locationName = place.formatted_address;

      this.overlays = [
        new google.maps.Marker(
          {
            title: locationName,
            position: { lat: lat, lng: lng },
            animation: google.maps.Animation.DROP
          }
        )
      ];
      this.gmap.map.panTo({ lat: lat, lng: lng });
      this.taskForm.patchValue({
        location: locationName,
        locationLat: lat,
        locationLng: lng
      });
    }
    else {
      this.overlays = [
        new google.maps.Marker(
          {
          }
        )
      ];
      this.taskForm.patchValue({
        locationLat: null,
        locationLng: null
      });
    }

    this.ref.detectChanges();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }


  searchAutoCompleteProperties(event) {

  this.isPropertySelected = false;
    if (event.query)
      this.onGetPropertySearch(event.query);
  }

 getProperty(event) {
   debugger;
  this.isPropertySelected = true;
  //this.PropertySelected = this.Properties.find(p => p.propertyId === event.value);
  this.taskService.getProperty(event.value).subscribe(
    (property: Property) => {
      this.PropertySelected = property;
  //   }
  // );
  if (this.PropertySelected.propertyCoords.length > 0)
  {
    this.overlays = [
      new google.maps.Marker(
        {
          title: this.PropertySelected.propertyLocality,
          position: { lat: parseFloat(this.PropertySelected.propertyCoords[0]), lng: parseFloat(this.PropertySelected.propertyCoords[1]) },
          animation: google.maps.Animation.DROP
        }
      )
    ];
    this.gmap.map.panTo({ lat: parseFloat(this.PropertySelected.propertyCoords[0]), lng: parseFloat(this.PropertySelected.propertyCoords[1]) });

    debugger;

    this.taskForm.patchValue({
      location: this.PropertySelected.propertyAddress,
      locationLat: parseFloat(this.PropertySelected.propertyCoords[0]),
      locationLng: parseFloat(this.PropertySelected.propertyCoords[1])
    });

  }
});
 }

  checkMeetingEvent(event){
    this.contactService.getContact(event.value).subscribe(
      (contact: Contact) => {
        var taskType = this.taskForm.get('taskTypeId').value;
        var taskTypeLabel = this.taskTypesOptions.find(e=>e.value === taskType).label;
        if(PrepopulatedTaskTypes.Viewing !== taskTypeLabel) {

        // Check if the contact has coordinates associated
        if (contact.locationLat == null && contact.locationLng == null )
          return;

          let formattedAddress = "";

          if (contact.addressLine1 != null && contact.addressLine2 != null && contact.addressLine3 != null && contact.addressLine4 != null)
          formattedAddress = contact.addressLine1 + " " +contact.addressLine2 + " " +contact.addressLine3 + " " +contact.addressLine4

          this.overlays = [
            new google.maps.Marker(
              {
                title: formattedAddress,
                position: { lat: contact.locationLat, lng: contact.locationLng },
                animation: google.maps.Animation.DROP
              }
            )
          ];


          this.gmap.map.panTo({ lat: contact.locationLat, lng: contact.locationLng });
          this.taskForm.patchValue({
            location: formattedAddress,
            locationLat: contact.locationLat,
            locationLng: contact.locationLng
          });
        }
      }
    );
  }


  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  handleDropdownProperties(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.propertiesAutoComplete.panelVisible) {
      this.propertiesAutoComplete.hide();
    } else {
      this.propertiesAutoComplete.show();
    }
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const taskId = params['id'];
        //this.propParam = params['propId'];
        this.isEdit = taskId != null; // - Returns true if an id was present within the url

        if (this.isEdit)
          this.onGetTask(taskId);
        else
          this.initForm();
      }
    );
  }

  initForm() {
    this.onGetUsers();
    this.onGetProperties();
debugger;


        // if (!this.isEdit && this.propParam != 0)
        // {
        //     this.getProperty(this.propParam);
        // }

    this.taskForm = this.formBuilder.group({
      taskDescription: this.formBuilder.control(this.task.taskDescription, [Validators.required]),
      taskTypeId: this.formBuilder.control(this.task.taskType.id, [Validators.required]),
      reminderDate: this.formBuilder.control(
        this.task.reminderDate ? new Date(this.task.reminderDate) : this.formatMinutesInDateToNearestFiveMinutes(new Date()),
        [Validators.required]),
      assignedUserIds: this.formBuilder.control(this.task.userTasks.map(uT => uT.userId), [Validators.required]), // - Assigned user IDs
      assignedContactIds: this.formBuilder.control(this.task.contactTasks.map(cT => cT.contactId),[Validators.required]),               // - Assigned contact IDs
      assignedOpportunityIds: this.formBuilder.control(this.task.opportunityTasks.map(oT => oT.opportunityId)),   // - Assigned opportunity IDs
      location: this.formBuilder.control(this.PropertySelected.propertyAddress != null ? this.PropertySelected.propertyAddress : this.task.location),
      propertyId: this.formBuilder.control(this.task.propertyId, [Validators.required]),
      locationLat: this.formBuilder.control(this.PropertySelected.propertyCoords != null && this.PropertySelected.propertyCoords.length > 0
                                            ? this.PropertySelected.propertyCoords[0] : this.task.locationLat),
      locationLng: this.formBuilder.control(this.PropertySelected.propertyCoords != null && this.PropertySelected.propertyCoords.length > 0
                                            ? this.PropertySelected.propertyCoords[1] : this.task.locationLng),

      sendEmailToUsers: this.formBuilder.control(false),    // - Emails are sent to Users by default
      sendEmailToContacts: this.formBuilder.control(false), // - Emails are NOT sent to Contacts by default
      calendarDuration: this.formBuilder.control(
        this.defaultCalendarDuration,
        [
          Validators.required,
          Validators.min(this.minCalendarDuration),
          Validators.max(this.maxCalendarDuration)
        ]
      )
    });

    debugger;
    if (this.isEdit && this.task.contactTasks.length > 0) {
      let defaultSelectItems: SelectItem[] = [];

      this.task.contactTasks.map(cT => {
        let c = cT.contact;
        let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
        c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
        c.companyName ? labelText += " / Company: " + c.companyName : "";
        c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
        c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
        c.email ? labelText += " / Email: " + c.email : "";

        let defaultSelectItem: SelectItem = {
          label: labelText,
          value: cT.contactId
        };

        defaultSelectItems.push(defaultSelectItem);
      });

      let defaultValue = this.task.contactTasks.length > 0 ? defaultSelectItems : null;

      this.taskForm.patchValue({
        assignedContactIds: defaultValue
      });

      this.changeTaskType();
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {
          // - Add the contact with the specified ID as one of the assigned contacts
          if (!this.isEdit) {
            this.contactService.getContact(+contactId).subscribe(
              (contact: Contact) => {
                let defaultValue: SelectItem[] = [{
                  label: contact.fullName,
                  value: +contactId
                }];

                this.taskForm.patchValue({
                  assignedContactIds: defaultValue
                });
              }
            );
          }
        }

        let opportunityId = queryParam['opportunity'];
        if (opportunityId != null) {
          // - Add the opportunity with the specified ID as one of the assigned opportunitys
          if (!this.isEdit) {
            this.taskForm.patchValue({
              assignedOpportunityIds: [+opportunityId]
            });
          }
        }
      }
    );

    this.isFormInit = true;

    this.onGetTaskTypes(); // - Retrieve all task types to populate the Task Type dropdown
    this.onFormValueChanges();
    this.initGoogleMap();
  }

  formatMinutesInDateToNearestFiveMinutes(date: Date) {
    // Obtained from: https://stackoverflow.com/a/10789415
    let coeff = 1000 * 60 * 5;
    let rounded = new Date(Math.round(date.getTime() / coeff) * coeff)

    return rounded;
  }

  onFormValueChanges() {
    this.onSendEmailChange();
    this.onAssignedContactsChange();
  }

  onGetTaskTypes() {
    let combined$ = combineLatest(
      this.taskTypeService.getTaskTypes(),
      this.route.queryParams,
      (taskTypes, queryParam) => ({taskTypes, queryParam})
    );

    combined$.pipe(
      tap((result) => {
        // Store retrieved task types in the taskTypesOptions select list
        result.taskTypes.map(cT => this._taskTypesOptions.push({ label: cT.name, value: cT.id }));

        this.taskTypesOptions = this._taskTypesOptions
        // There must be at least one Task Type created to create or edit a task
        if (this.taskTypesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let viewingTaskTypeId = this.taskTypesOptions.find(o => o.label === PrepopulatedTaskTypes.Viewing).value;
        let defaultValue = 0;

        if (!this.isEdit)
          defaultValue = viewingTaskTypeId;
        else
          defaultValue = this.task.taskType.id;

        this.taskForm.patchValue({
          taskTypeId: defaultValue
        });
      }),
      map(result => {
        let paramTaskType = result.queryParam['viewingtype'];

        if (!this.isEdit && paramTaskType != null)
        {
          let viewingTaskTypeId = this.taskTypesOptions.find(o => o.label === paramTaskType).value;
          this.taskForm.get('taskTypeId').setValue(viewingTaskTypeId);
        }
      })
    ).subscribe(
      // Success
      ()=>{},
      // Error
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving task types", error);
      }
    );
  }

  //** Updates made to form when checkbox email values change */
  onSendEmailChange() {
    this.taskForm.controls["sendEmailToUsers"].valueChanges.subscribe(
      (sendEmailToUsers: boolean) => {
        let sendEmailToContacts = this.taskForm.controls["sendEmailToContacts"].value;
        this.updateSpinner(sendEmailToUsers, sendEmailToContacts);
      }
    );
    this.taskForm.controls["sendEmailToContacts"].valueChanges.subscribe(
      (sendEmailToContacts: boolean) => {
        let sendEmailToUsers = this.taskForm.controls["sendEmailToUsers"].value;
        this.updateSpinner(sendEmailToContacts, sendEmailToUsers);
      }
    );
  }

  //** Updates made to Opportunity Options when assigned contacts change */
  onAssignedContactsChange() {
    this.taskForm.controls["assignedContactIds"].valueChanges.subscribe(
      (assignedContactSelectItems: SelectItem[]) => {
        let observableList: Observable<Opportunity[]>[] = [];

        assignedContactSelectItems.map(selectItem => {
          observableList.push(this.opportunityService.getOpportunitiesByAssignedContactId(selectItem.value));
        });

        this.opportunityOptions = [];

        Observable.forkJoin(observableList).subscribe(
          (results: Opportunity[][]) => {
            results.map(r => {
              r.map(o => {
                this.opportunityOptions.push({ label: o.name + " - " + o.assignedContact.fullName, value: o.id });
              });
            });
          }
        );
      }
    );
  }

  //** Updates the spinner based on send email checkboxes values */
  updateSpinner(sendEmailToUsers: boolean, sendEmailToContacts: boolean) {
    if (sendEmailToUsers || sendEmailToContacts) {
      this.taskForm.controls['calendarDuration'].setValidators([
        Validators.required,
        Validators.min(this.minCalendarDuration),
        Validators.max(this.maxCalendarDuration)
      ]);
      this.isEmailGoingToSend = true;
      this.taskForm.controls['calendarDuration'].updateValueAndValidity();
    }
    else {
      this.taskForm.controls['calendarDuration'].setValidators([
        Validators.min(this.minCalendarDuration),
        Validators.max(this.maxCalendarDuration)
      ]);
      this.isEmailGoingToSend = false;
      this.taskForm.controls['calendarDuration'].updateValueAndValidity();
    }
  }

  onGetTask(id: number) {
    this.taskService.getTask(id).subscribe(
      (task: Task) => {
        this.task = task;

        // - Get the opportunities of the contacts assigned to this task
        task.contactTasks.map(cT => {
          this.opportunityService.getOpportunitiesByAssignedContactId(cT.contactId).subscribe(
            (opportunities: Opportunity[]) => {
              opportunities.map(o => {
                this.opportunityOptions.push({ label: o.name + " - " + o.assignedContact.fullName, value: o.id });
              });
            }
          );
        });

        this.initForm();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving task", error);
        this.router.navigate(['/error/404']);
      }
    );
  }

  isTaskTypeMeeting() {
    const taskTypeIdValue = this.taskForm.get('taskTypeId').value;
    const taskType = this.taskTypesOptions.find(o => o.value === taskTypeIdValue);

    if (taskType) {
      const taskTypeLabel = taskType.label;
      if (taskTypeLabel === PrepopulatedTaskTypes.Meeting)
        return true;
    }

    return false;
  }

  isTaskTypeViewing() {
    const taskTypeIdValue = this.taskForm.get('taskTypeId').value;
    const taskType = this.taskTypesOptions.find(o => o.value === taskTypeIdValue);

    if (taskType) {
      const taskTypeLabel = taskType.label;
      if (taskTypeLabel === PrepopulatedTaskTypes.Viewing)
        return true;
    }
    return false;
  }

  changeTaskType() {
    if (this.isTaskTypeViewing()) {
      this.taskForm.get('propertyId').setValidators([Validators.required]);
    }
    else {
      this.taskForm.get('propertyId').clearValidators();
    }

    this.taskForm.get('propertyId').updateValueAndValidity();
  }

  onTaskTypeChange(event) {
    this.changeTaskType();
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {

          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.contactsOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetPropertySearch(searchTerm: string) {

    this.taskService.getPropertiesSearch(searchTerm).subscribe(
      (properties: PropertyIdCode[]) => {

        debugger;
        let propOpt = [];
        // - Store the retrieved property codes as options for dropdown
        properties.map(p => { propOpt.push({ label: p.customCode, value: p.propertyId}); });

        this.propCodeOptions = propOpt;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving properties", error);
      }
    );


  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // - Store the retrieved users as options for multiselect
        users.map(u => {
          this.usersOptions.push({ label: u.fullName, value: u.id });
        });

        // - Add the current user as one of the assigned users
        if (!this.isEdit) {
          this.taskForm.patchValue({
            assignedUserIds: [this.authService.applicationProfileUser().id]
          });
        } else {
          this.taskForm.patchValue({
            assignedUserIds: this.task.userTasks.map(uT => uT.userId)
          });
        }
      }
    );
  }

  onGetProperties() {
    let combined$ = combineLatest(
      this.taskService.getPropertyCodes(),
      this.route.queryParams,
      (task, queryParams) => ({task, queryParams})
    );

    combined$.pipe(
      tap(result => {
        const res: PropertyIdCode[] = (<any>result).task;
        res.map(c => this.propCodeOptions.push({ label: c.customCode, value: c.propertyId }));
        //result.task.properties.map(p => .push(p));

        if (this.isEdit && this.propCodeOptions.length > 0 && this.task.propertyId != null) {

          let defaultPropSelected: SelectItem = this.propCodeOptions.find(o => o.value === this.task.propertyId);
          this.taskForm.patchValue({
            propertyId: defaultPropSelected
          });
          this.getProperty(defaultPropSelected);
        }
      }),
      map(result => {
        let paramPropertyId = result.queryParams['propId'];

        if (!this.isEdit && paramPropertyId != null)
        {
          let defaultPropSelected: SelectItem = this.propCodeOptions.find(o => o.value === parseInt(paramPropertyId));
          this.taskForm.patchValue({
            propertyId: defaultPropSelected
          });
          this.getProperty(defaultPropSelected);
        }
      })
    ).subscribe(
      // Success
      ()=>{
        this.taskTypesOptions = this._taskTypesOptions
      },
      // error
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding properties", error);
        this.taskTypesOptions = this._taskTypesOptions.filter(option => option.label !== PrepopulatedTaskTypes.Viewing)

        const meeting = this._taskTypesOptions.find(option => option.label === PrepopulatedTaskTypes.Meeting)
        this.taskForm.patchValue({
          taskTypeId: meeting.value
        })
        this.changeTaskType()
      }
    )
  }

  onAddTask(newTask: Task, sendEmailToUsers: boolean, sendEmailToContacts: boolean) {
    this.taskService.addTask(newTask, sendEmailToUsers, sendEmailToContacts).subscribe(
      (response: Response) => {
        this.taskService.taskCounterChanged.next();
        this.toastService.createSuccessMessage("Success", "The task has been created.");
        this.router.navigate(['/tasks/list']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding task", error);
      }
    );
  }

  onUpdateTask(newTask: Task, sendEmailToUsers: boolean, sendEmailToContacts: boolean) {
    this.taskService.updateTask(newTask, sendEmailToUsers, sendEmailToContacts).subscribe(
      (response: Response) => {
        this.taskService.taskCounterChanged.next();
        this.toastService.createSuccessMessage("Success", "The task has been updated.");
        this.router.navigate(['/tasks/list']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating task", error);
      }
    );
  }

  onSubmit() {
    let newTask = this.formBuilder.sanitizeFormValues(this.taskForm).value;

    let meetingTaskTypeId = this.taskTypesOptions.find(o => o.label === PrepopulatedTaskTypes.Meeting).value;

    // - The values regarding task location should only be passed to API if task type is set to meeting
    if (newTask.taskTypeId !== meetingTaskTypeId) {
      //newTask.location = null;
      newTask.locationLat = null;
      newTask.locationLng = null;
    }

    // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newTask.assignedContactIds[0] && newTask.assignedContactIds[0].value) {
      newTask.assignedContactIds.forEach(
        (assignedContactId, index) => {
          newTask.assignedContactIds[index] = assignedContactId.value;
        }
      );
    }
    else
      newTask.assignedContactIds = [];

    newTask.contactTasks = this.populateContactTasks(newTask.assignedContactIds);
    newTask.opportunityTasks = this.populateOpportunityTasks(newTask.assignedOpportunityIds);
    newTask.userTasks = this.populateUserTasks(newTask.assignedUserIds);

    if (this.isTaskTypeViewing())
    {
      newTask.propertyId = newTask.propertyId.value;
    }

    // Check if opportunityTasks values are in opportunityOptions
    // This check is made so that we will only have opportunityTasks of the contacts currently assigned to this task while editing the form
    for (let opportunityTask of newTask.opportunityTasks) {
      // Remove opportunityTask if it is no longer a selectable option
      if (!this.opportunityOptions.find(o => o.value === opportunityTask.opportunityId))
        newTask.opportunityTasks = newTask.opportunityTasks.filter(o => o.opportunityId !== opportunityTask.opportunityId);
    }

    let sendEmailToUsers = newTask.sendEmailToUsers;
    let sendEmailToContacts = newTask.sendEmailToContacts;

    // Omitted for now
    // Not all contacts have an email so a check is performed to inform the user
    // if (sendEmailToContacts) {
    //   this.onCheckAssignedContactEmails(this.assignedContacts);
    // }

    if (this.isEdit) {
      newTask.id = this.task.id; // - Set id of edited task to its original id
      newTask.isTaskComplete = this.task.isTaskComplete;
      newTask.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateTask(newTask, sendEmailToUsers, sendEmailToContacts);
    }
    else {
      newTask.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddTask(newTask, sendEmailToUsers, sendEmailToContacts);
    }
  }

  populateContactTasks(assignedContactIds: number[]) {
    let contactTasks: {}[] = [];

    for (let id of assignedContactIds) {
      let contactTask = {
        contactId: id,
        taskId: this.task.id,
        createdByUserAccountId: this.authService.applicationProfileUser().id,
      };
      contactTasks.push(contactTask);
    }

    return contactTasks;
  }

  populateOpportunityTasks(assignedOpportunityIds: number[]) {
    let opportunityTasks: {}[] = [];

    for (let id of assignedOpportunityIds) {
      let opportunityTask = {
        opportunityId: id,
        taskId: this.task.id,
        createdByUserAccountId: this.authService.applicationProfileUser().id,
      };
      opportunityTasks.push(opportunityTask);
    }

    return opportunityTasks;
  }

  populateUserTasks(assignedUserIds: number[]) {
    let userTasks: {}[] = [];

    for (let id of assignedUserIds) {
      let userTask = {
        userId: id,
        taskId: this.task.id,
        createdByUserAccountId: this.authService.applicationProfileUser().id,
      };
      userTasks.push(userTask);
    }

    return userTasks;
  }

  onAskForCommunicationLog(task: Task) {
    let firstContact = task.contactTasks[0].contact;

    this.confirmationService.confirm({
      header: 'Communication Log',
      message: 'The task has been marked as complete. Would you now like to create a new communication log for ' + firstContact.fullName + '?',
      accept: () => {
        this.router.navigate(['/contacts', firstContact.id, 'communication-history', 'new']);
      }
    });
  }

  onCheckAssignedContactEmails(assignedContacts: Contact[]) {
    let contactsWithNoEmail = assignedContacts.filter(c => !c.email || c.email.length === 0).map(c => c.fullName);

    if (contactsWithNoEmail && contactsWithNoEmail.length > 0) {
      this.contactsNoEmail = true; // - Setting to true for PrimeNG dialog box (See: Bottom of HTML template of this component)
      let message = "The following contacts do not have an email address stored so an email cannot be sent to them:";
      message += "<br><br>";
      contactsWithNoEmail.forEach(
        (contactName, index) => {
          message += "<strong>" + contactName + "</strong>";
          if (index < contactsWithNoEmail.length - 1)
            message += "<br>";
        }
      );

      this.confirmationService.confirm({
        header: 'Please Note',
        message: message,
        rejectVisible: false
      });
    }
  }

  onCompleteTask(task: Task) {
    let completedTask = Object.assign(this.task); // Copy of task
    task.updatedByUserAccountId = this.authService.applicationProfileUser().id; // Actual task

    this.taskService.toggleTaskCompletion(task).subscribe(
      (response: Response) => {
        // NOTE: The update is made in the API!!!
        // Here I am setting the task to complete because the update was successful, so we want the change to be shown to the user
        completedTask.isTaskComplete = !completedTask.isTaskComplete;
        this.taskService.taskCounterChanged.next();

        // Only ask for new comm. log if task is complete and is assigned to at least one contact
        if (task.contactTasks.length > 0 && completedTask.isTaskComplete)
          this.onAskForCommunicationLog(completedTask);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error completing task", error);
      }
    );
  }
}
