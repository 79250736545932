import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SystemConfiguration, SystemConfigurationService } from './views/shared/system-config.service';

@Component({
     selector: 'app-root',
     templateUrl: './app.component.html',
     styleUrls: ['./app.component.scss']
})
export class AppComponent {

     public configKey: string;

     public constructor(
          private titleService: Title,
          private config: SystemConfigurationService
     ) { }

     ngOnInit() {
          debugger;
          this.config.getConfiguration().subscribe((_: SystemConfiguration) => {
               this.configKey = _.keyConfiguration
               if (this.configKey == "SALES") {
                    this.setTitle("CRM - SCENIC - by ISB Technologies")
               } else if (this.configKey == "ACQUISITION") {
                    this.setTitle("Site Acquisition - SCENIC - by ISB Technologies")
               }
          });
     }

     public setTitle(newTitle: string) {
          this.titleService.setTitle(newTitle);
     }
}
