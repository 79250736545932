import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { ComplaintsComponent } from "../complaints/complaints.component";
import { ComplaintListComponent } from "../complaints/complaint-list/complaint-list.component";
import { ComplaintManageComponent } from "../complaints/complaint-manage/complaint-manage.component";
import { ComplaintTimelineComponent } from './complaint-timeline/complaint-timeline.component';

const complaintsRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'snaglist', component: ComplaintsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: ComplaintListComponent },
          { path: ':id/edit', component: ComplaintManageComponent },
          { path: ':id/timeline', component: ComplaintTimelineComponent },
          { path: 'new', component: ComplaintManageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(complaintsRoutes)],
  exports: [RouterModule]
})
export class ComplaintsRoutingModule { }